/* ===========================================================
  FORMS
=========================================================== */

input[type='text'],
input[type='email'],
input[type='url'],
input[type='tel'],
input[type='password'],
input[type='search'],
input[type='date'],
input[type='datetime-local'],
input[type='month'],
input[type='week'],
input[type='number'] {
	padding: 5px 8px;
	border: 0;
	border-bottom: 5px $black solid;
	border-radius: 0;
	transition: all 0.3s ease;
	@include font-bold;
	@include rem(font-size, 22px);
	@include rem(line-height, 25px);

	@include placeholder {
		color: $black;
		transition: opacity 0.3s ease;
	}

	&:focus {
		outline: none;
		border-color: $red;

		@include placeholder {
			opacity: 0.3;
		}
	}

	.form-field-wrapper & {
		width: 100%;

		&:focus {
			@include placeholder {
				opacity: 0;
			}

			& + label {
				opacity: 1;
				visibility: visible;
				transform: translate3d(0, 0, 0);
				transition: visiblity 0.1s ease, opacity 0.2s ease, transform 0.35s ease;
			}
		}
	}
}

textarea {
	min-width: 250px;
	max-width: 100%;
	min-height: 160px;
	padding: 12px 8px;
	background: #f5f5f5;
	border: 0;
	border-bottom: 5px $black solid;
	border-radius: 0;
	resize: none;
	transition: all 0.3s ease;
	@include font-bold;
	@include rem(font-size, 22px);
	@include rem(line-height, 25px);

	@include placeholder {
		color: $black;
		transition: opacity 0.3s ease;
	}

	&:focus {
		outline: none;
		border-color: $red;

		@include placeholder {
			opacity: 0.2;
		}
	}

	.form-field-wrapper & {
		width: 100%;
	}
}

input[type='submit'] {
	padding: 12px 45px;
	border: 0;
	border-radius: 0;
	background: $black;
	color: $white;
	text-transform: uppercase;
	text-align: center;
	transition: background 0.3s ease;
	cursor: pointer;
	@include font-black;
	@include rem(font-size, 20px);
	@include rem(line-height, 20px);

	.no-touch &:hover,
	&:focus {
		outline: none;
		background: $red;
		transition: background 0.3s ease;
	}
}

label {
	display: block;
	@include font-bold;
}

.form-field-wrapper {
	position: relative;
	margin-bottom: 1.5em;
	padding-top: 15px;

	&.col-sm-6 {
		@include bp--sm {
			&:nth-of-type(odd) {
				padding-right: 10px;
			}

			&:nth-of-type(even) {
				padding-left: 10px;
			}
		}
	}

	label {
		position: absolute;
		top: 0;
		left: 13px;
		letter-spacing: 0.075em;
		text-transform: uppercase;
		opacity: 0;
		visibility: hidden;
		transform: translate3d(0, 25px, 0);
		backface-visibility: hidden;
		transition: opacity 0.2s ease, transform 0.35s ease;
		@include font-black;
		@include rem(font-size, 11px);
		@include rem(line-height, 11px);
	}

	&.input--filled {
		label {
			opacity: 1;
			visibility: visible;
			transform: translate3d(0, 0, 0);
			transition: visibility 0.1s ease, opacity 0.2s ease, transform 0.35s ease;
		}
	}
}

/* ===========================================================
  WPCF7
=========================================================== */

.wpcf7-response-output {
	margin: 2em 0 0 !important;
	padding: 0 !important;
	border: 0 !important;
	font-weight: 700 !important;

	&.wpcf7-validation-errors {
		color: $red !important;
	}
}

.wpcf7-not-valid-tip {
	padding: 10px 0 0;
	color: $red !important;
	font-weight: 700 !important;
}

div.wpcf7 .ajax-loader {
	display: block;
	margin: 10px 0 0;
}

.wpcf7-form-control-wrap {
	position: static !important;
}

