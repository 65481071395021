/* ===========================================================
  SOCIAL Wall
=========================================================== */


.social-wall{
	position: relative;
	padding-bottom: 0px;
    min-height: 450px;

    .container{
        background: transparent;
	    position: relative;
	    border-top: 20px solid black;
    }
}


.grey-angle{
	position: absolute;
	top:50px;
	bottom:50px;
	left:20%;
	right: 0;

	//clip-path: polygon(0 0,100% 0,100% 100%,33% 100%);
  /* clip-path: polygon(
            0 0,
            100% 0,
            100% -100vw,
            calc(-0.33*100vw) -100vw
        );
        */

    clip-path: polygon(0 0,100% 0,100% 100vw,-33vw -50vw);
}

.grey-angle::before{
        display: block;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0%;
    bottom: 0;
    z-index: 0;
    background: #f6f6f6;
}

.social-wall-title{
	margin: 40px 0 20px;
	display: flex;
	align-items: baseline;
}

.social-wall-title .hero-slide__label{
	margin: 0 10px;
}
.social-wall-title img{
	width: 110px;
}

.social-wall-links{
   // background-color: peachpuff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;

    .social-wall-links-msg{
      //  border: 1px solid blue;
    }

    .social-wall-links-buttons{
       // border: 1px solid red;

        ul.social__share{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            li{
                margin: 0 0 0 30px;
                
                a{
  
                    border: 1px solid #ebebeb;
                    padding: 10px;
                    background-color: #fff;
                    color: #a1a1a1;
                    transition: color .3s;

                    &:hover{
                        color: #e51937;
                    }

                    &::before{
                        content: none;
                    }

                    .share-icon{
                        bottom: auto;
                        color: #e51937;
                    }
                }
            }
        } 
    }
}

@media only screen and (max-width: 1000px){

    .social-wall-title{
        justify-content: center;

    }
    .social-wall-links{

        flex-direction: column;
        width: 100%;
    }

    .social-wall-links .social-wall-links-buttons ul.social__share{
        margin: 10px 0;
    }
}


@media only screen and (max-width: 600px){

    

    .social-wall-links-buttons{
        width: 60%;
    }

    .social-wall-links .social-wall-links-buttons ul.social__share{
        flex-direction: column;
       
        width: 100%;

        li{
            margin: 10px 0;
            width: 100%;

            a{
                width: 100%;
            }
        }



    }

    .crt-widget-mosaic .crt-feed{
        overflow: hidden;
         display: grid;
        grid-template-columns: 1fr 1fr;
        height: auto !important;

        & :first-child {
            grid-column: 1 / -1;
        }

        .crt-mosaic-post{
            position: relative !important;
            width: 100% !important;
            height: auto !important;
            left: 0 !important;
            top: 0 !important;
        }
    }
}


