/* ===========================================================
  PERSON
=========================================================== */

.person {
	position: relative;
	max-width: 280px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 15px;

	@include bp-custom(600px) {
		display: flex;
		align-items: stretch;
		max-width: 100%;
		margin: 0 0 30px;
	}

	@include bp--lg {
		margin-bottom: 50px;
	}

	.person-bio,
	.person-photo {
		position: relative;
		z-index: 2;
	}

	.person-photo {
		@include bp-custom(600px) {
			width: 50%;
		}

		@include bp--lg {
			width: 33%;
		}
	}

	.person-bio {
		padding: 20px 0 20px 20px;

		@include bp-custom(600px) {
			width: 50%;
		}

		@include bp--lg {
			width: 67%;
			padding: 30px 0 20px 65px;
		}
	}

	&.no-bio {
		display: block;

		@include bp--lg {
			max-width: 280px;
		}

		.person-link {
			@include bp-custom(600px) {
				display: flex;
				width: 100%;
				align-items: stretch;
			}

			@include bp--lg {
				display: block;
			}
		}

		.person-photo {
			@include bp-custom(600px) {
				width: 50%;
			}

			@include bp--lg {
				width: 100%;
			}
		}

		.person-bio {
			padding: 20px;

			@include bp-custom(600px) {
				padding: 20px 0 20px 20px;
				width: 50%;
			}

			@include bp--lg {
				width: 100%;
				padding-top: 20px;
				padding-right: 0;
				padding-bottom: 0;

				.cssclippathpolygon & {
					padding-left: 33%;
				}
			}
		}
	}
}

.person-list {
	@include bp--lg {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		padding: 35px 0;
	}

	.person {
		@include bp--lg {
			width: 33.33%;
		}
	}

	&--archive {
		.person {
			@include bp--lg {
				width: 25%;
			}
		}
	}
}

.person-bio {
	h3 {
		margin: 0 0 10px;
		@include rem(line-height, 25px);

		@include bp--md(max) {
			@include rem(font-size, 26px);
			@include rem(line-height, 26px);

			.person & {
				@include rem(font-size, 20px);
				@include rem(line-height, 20px);
			}
		}

		.player-role {
			position: relative;
			display: inline-block;
			margin-left: 5px;

			&--text {
				display: block;
				margin-left: 0;
				vertical-align: middle;
				@include rem(font-size, 13px);
				@include rem(line-height, 25px);

				.tax-fh-team & {
					vertical-align: top;
				}

				.featured--players & {
					vertical-align: top;
				}

				@include bp--lg {
					display: inline-block;
					margin-left: 5px;
				}

				@include bp--md(max) {
					@include rem(line-height, 20px);
				}
			}

			&--icon {
				vertical-align: top;
				width: 25px;
				height: 25px;
				border-radius: 50%;
				background: #d1d1d1;
				text-align: center;
				transition: background 0.3s ease;

				svg {
					position: absolute;
					top: 50%;
					left: 50%;
					margin-top: -7.5px;
					margin-left: -7.5px;
					width: 15px;
					height: 15px;
					transition: fill 0.3s ease;
				}

				@include bp--md(max) {
					width: 20px;
					height: 20px;

					.player-slides & {
						margin-top: 2px;
					}

					svg {
						margin-top: -6px;
						margin-left: -6px;
						width: 12px;
						height: 12px;
					}
				}
			}
		}

		a {
			color: $black;
			transition: color 0.3s ease;

			.no-touch &:hover {
				color: $red;
				transition: color 0.3s ease;

				.player-role {
					&--icon {
						background: $red-lt;
						transition: background 0.3s ease;

						svg {
							fill: $red;
							transition: fill 0.3s ease;
						}
					}
				}
			}
		}
	}

	h4 {
		margin: 0;
		text-transform: none;
		@include font-bold;
		@include rem(font-size, 18px);
		@include rem(line-height, 22px);

		@include bp--sm {
			@include rem(font-size, 22px);
			@include rem(line-height, 26px);
		}

		.person & {
			@include rem(font-size, 16px);
			@include rem(line-height, 20px);

			@include bp--lg {
				@include rem(font-size, 20px);
				@include rem(line-height, 24px);
			}
		}
	}

	p {
		margin: 15px 0 0 -14px;
		text-transform: uppercase;
		@include font-black;
		@include rem(font-size, 13px);
		@include rem(line-height, 16px);

		@include bp-custom(600px) {
			margin: 25px 0 0 -14px;
		}

		a {
			display: inline-block;
			color: $black;

			&::before,
			&::after {
				display: inline-block;
				vertical-align: middle;
				content: '';
				opacity: 0;
				width: 4px;
				height: 16px;
				background: $red;
				transition: transform 0.3s, opacity 0.2s;
			}

			&::before {
				margin-right: 10px;
				transform: translateX(20px);
			}

			&::after {
				margin-left: 10px;
				transform: translateX(-20px);
			}

			span {
				position: relative;
				display: inline-block;
				vertical-align: middle;
				@include rem(line-height, 16px);
			}

			.no-touch &:hover {
				&::before,
				&::after {
					opacity: 1;
					transform: translateX(0);
				}
			}
		}
	}

	&--small {
		h3 {
			@include bp--lg {
				@include rem(font-size, 20px);
				@include rem(line-height, 22px);
			}

			.player-role {
				display: block;
				margin-left: 0;

				&--text {
					@include bp--lg {
						@include rem(line-height, 22px);
					}
				}
			}
		}
	}

	&.in-content {
		margin: 0 0 1.5em;

		@include bp--sm {
			margin: 0 0 2.5em;
			column-count: 2;
			column-gap: 20px;
		}

		h4 {
			@include rem(font-size, 20px);
			@include rem(line-height, 22px);
		}
	}
}

.person-photo {
	.photo-wrap {
		position: relative;
		width: 280px;
		height: 220px;

		@include bp-custom(600px) {
			.cssclippathpolygon & {
				padding-left: 25px;
			}
		}
	
		.polygon {
			display: block;
			position: absolute;
			top: 15%;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
			background: $white;
			overflow: hidden;

			.coach & {
				background: $gray;
			}

			&__bg {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				width: 100%;
				height: 100%;
				filter: blur(10px);
				backface-visibility: hidden;
				opacity: 1;
				transition: opacity 0.05s ease;

				.loaded & {
					opacity: 1;
					transition: opacity 0.3s ease;
					transition-delay: 0.7s;
				}
			}
		}

		.cssclippathpolygon & {
			/* clip-path: polygon(0 0, 67% 0, 100% 100%, 33% 100%); */
			clip-path: polygon(0 0,100% 0,84% 58%,100% 100%,33% 100%);

			.polygon {
				overflow: auto;
				clip-path: polygon(0 0, 67% 0, 100% 100%, 33% 100%);

				&__bg {
					clip-path: polygon(0 0, 67% 0, 100% 100%, 33% 100%);
				}
			}

			.photo-border {
				position: absolute;
				z-index: 3;
				width: 100%;
				background: transparent;
				transition: background 0.3s ease;

				&--left {
					top: 34px;
					left: 14px;
					bottom: 0;
					clip-path: polygon(0 0, 6% 0, 35% 100%, 28% 100%);
				}

				&--bottom {
					left: 0;
					right: 0;
					bottom: 0;
					height: 15px;
				}
			}
		}

		.no-touch.cssclippathpolygon & {
			a:hover {
				.photo-border {
					background: $red;
					transition: background 0.3s ease;
				}
			}
		}

		img {
			position: absolute;
			right: 0;
			bottom: 0;
			z-index: 2;
			display: block;
			max-width: 100%;
			max-height: 100%;
			width: auto;
			height: auto;
			margin: 0 auto;
		}

		a:focus {
			outline: none;
		}
	}
}

/* Hovers for full element link */

.person-link {
	h3 {
		transition: color 0.3s ease;
	}

	&:focus {
		outline: none;
	}

	.no-touch &:hover {
		.person-bio {
			h3 {
				transition: color 0.3s ease;
				color: $red;

				.player-role {
					&--icon {
						transition: background 0.3s ease;
						background: $red-lt;

						svg {
							transition: fill 0.3s ease;
							fill: $red;
						}
					}
				}
			}
		}

		.person-photo {
			.photo-wrap {
				.photo-border {
					opacity: 1;
					transition: opacity 0.3s ease;
				}
			}
		}
	}
}
