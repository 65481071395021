/* ===========================================================
  ANNOUNCEMENT
=========================================================== */

.announcement-banner {
	width: 100%;
	height: auto;

	a{
		width: 100%;
		height: auto;
		display: block;
		text-align: center;
		padding: 8px 20px;
	
		

		&.banner-red{
			background-color: $red;

			&:hover{

				.banner-link{
					color:  black;
				}
			}
		}

		&.banner-black{
			background-color: black;

			&:hover{

				.banner-link{
					color: $red;
				}
			}

			
		}

		h6{
			color: white;
			text-align: center;
			line-height: 18px;
		}

		.banner-link{
			transition: color 0.3s;
			text-decoration: underline;
		
		}

		
	}
	
}
