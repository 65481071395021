/* ===========================================================
  VIDEO
=========================================================== */

.home-video {
	&--featured {
		position: relative;
		margin-bottom: 0;
		overflow: hidden;
		height: calc((100vw * 9) / 16);

		@include bp--sm {
			width: 100vw;
			margin-bottom: 15px;
			height: calc(100vh - #{$header-height});

			.with-stats & {
				height: calc(100vh - #{$full-header-height});
			}
		}
	}

	&__text {
		padding: 0 20px;
		z-index: 499;
		text-align: center;
		transition: opacity 0.3s ease, z-index 0.6s ease;
		@include center-h--unk();

		h2 {
			margin-bottom: 20px;
			color: $white;
			text-transform: uppercase;
			@include rem(font-size, 26px);
			@include rem(line-height, 30px);

			@include bp--xs {
				@include rem(font-size, 34px);
				@include rem(line-height, 42px);
			}

			@include bp--sm {
				@include rem(font-size, 50px);
				@include rem(line-height, 60px);
			}

			@include bp--md {
				@include rem(font-size, 60px);
				@include rem(line-height, 70px);
			}
		}

		a.video-link {
			color: $white;
		}
	}

	&__wrap {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;

		.home-video__inner {
			@include bp--sm {
				position: absolute;
				top: -50%;
				left: -50%;
				width: 200%;
				height: 200%;
			}
		}

		video {
			width: 100%;
			height: auto;

			@include bp--sm {
				position: fixed;
				left: 0;
				bottom: 0;
				min-width: 100%;
				min-height: 100%;
				width: auto;
				height: auto;
				backface-visibility: hidden;
				perspective: 1000;
				z-index: 0;
			}
		}
	}

	&__cta {
		display: none;
		position: absolute;
		left: 0;
		right: 0;
		z-index: 3;
		bottom: 80px;
		margin: 0;
		text-align: center;
		color: $white;
		letter-spacing: 0.025em;
		text-transform: uppercase;
		@include rem(font-size, 12px);
		@include rem(line-height, 12px);
		@include font-black;

		@include bp--sm {
			display: block;
		}

		a {
			color: $white;
			transition: opacity 0.3s ease;

			.no-touch &:hover {
				opacity: 0.75;
				transition: opacity 0.3s ease;
			}
		}
	}

	&__poster {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 2;
		width: 100%;
		height: 100%;
		opacity: 1;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50% 50%;
		transition: opacity 0.3s ease, z-index 0.6s ease;

		@include bp--sm {
			background-attachment: fixed;
		}
	}

	&.is-playing {
		.home-video__text {
			opacity: 0;
			z-index: -999;
			transition: opacity 0.3s ease, z-index 0.6s ease;
		}

		.home-video__poster {
			opacity: 0;
			z-index: -999;
			transition: opacity 0.3s ease, z-index 0.6s ease;
		}
	}

	&--content {
		position: relative;
		height: calc((100vw * 9) / 16);
		overflow: hidden;

		&__wrap {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;

			video {
				width: 100%;
				height: auto;
			}
		}
	}
}

.youtube-responsive-container {
position:relative;
padding-bottom:56.25%;
padding-top:30px;
height:0;
overflow:hidden;
}

.youtube-responsive-container iframe, .youtube-responsive-container object, .youtube-responsive-container embed {
position:absolute;
top:0;
left:0;
width:100%;
height:100%;
}
