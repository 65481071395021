/* ===========================================================
   HELPERS
=========================================================== */

.visually-hidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.embed-container {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	max-width: 100%;

	iframe,
	object,
	embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.alignleft {
	display: block;
	margin: 0 0 1.5em;

	@include bp--xs {
		float: left;
		margin: 0 1em 1.5em 0;
	}
}

.aligncenter {
	display: block;
	margin: 0 auto;
}

.alignright {
	display: block;
	margin: 0 0 1.5em;

	@include bp--xs {
		float: right;
		margin: 0 0 1.5em 1em;
	}
}

.text-left {
	text-align: left;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}
