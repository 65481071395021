/* ===========================================================
  SEARCH
=========================================================== */

.subscribe {
	&__form {
		text-align: left;

		h6 {
			text-transform: uppercase;
			color: $red;
			letter-spacing: 0.025em;
			@include rem(font-size, 10px);
			@include rem(font-size, 10px);
		}

		form {
			position: relative;
			width: 320px;
			max-width: 100%;

			label {
				position: absolute;
				top: -18px;
				left: 0;
				letter-spacing: 0.075em;
				text-transform: uppercase;
				opacity: 0;
				visibility: hidden;
				transform: translate3d(0, 25px, 0);
				backface-visibility: hidden;
				transition: opacity 0.2s ease, transform 0.35s ease;
				@include font-black;
				@include rem(font-size, 11px);
				@include rem(line-height, 11px);
			}

			.input-submit-wrap {
				position: relative;

				&.input--filled {
					input[type='email'] + label {
						opacity: 1;
						visibility: visible;
						transform: translate3d(0, 0, 0);
						transition: visiblity 0.1s ease, opacity 0.2s ease, transform 0.35s ease;
					}
				}
			}

			input[type='email'] {
				display: block;
				width: 100%;
				padding: 0 15px 8px 0;
				background: none;
				border: 0;
				border-bottom: 4px #ccc solid;
				letter-spacing: 0.025em;
				color: $red;
				transition: border-color 0.3s ease;
				@include font-black;
				@include rem(font-size, 17px);
				@include rem(line-height, 17px);

				@include bp--sm {
					@include rem(font-size, 14px);
					@include rem(line-height, 14px);
				}

				@include placeholder {
					color: #a1a1a1;
					text-transform: uppercase;
					transition: opacity 0.2s ease;
				}

				&:focus {
					outline: none;
					border-color: $black;
					transition: border-color 0.3s ease;

					@include placeholder {
						opacity: 0;
						transition: opacity 0.2s ease;
					}

					& + label {
						opacity: 1;
						visibility: visible;
						transform: translate3d(0, 0, 0);
						transition: visiblity 0.1s ease, opacity 0.2s ease, transform 0.35s ease;
					}
				}
			}

			.arrow-submit {
				position: absolute;
				top: 0;
				right: 0;
				width: 15px;
				height: 29px;

				svg {
					position: absolute;
					top: 2px;
					right: 0;
					margin-left: auto;
					margin-right: auto;
					display: block;
					width: auto;
					height: 15px;
					fill: $gray-md;
					transition: fill 0.3s ease;

					@include bp--sm {
						top: 5px;
						height: 10px;
					}
				}

				input[type='submit'] {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					padding: 0;
					width: 15px;
					height: 29px;
					opacity: 0;
					cursor: pointer;
				}

				.no-touch &:hover {
					svg {
						fill: $red;
						transition: fill 0.3s ease;
					}
				}
			}
		}
	}
}

#mc_embed_signup div.mce_inline_error {
	margin: 0 !important;
	padding: 7px 0 !important;
	background: transparent !important;
	font-size: 12px !important;
	line-height: 12px !important;
	color: $red !important;
}

#mc_embed_signup input.mce_inline_error {
	border-color: $black !important;
}

#mc_embed_signup .response,
#mc_embed_signup #mce-success-response {
	margin: 0 !important;
	padding: 7px 0 !important;
	font-size: 12px !important;
	line-height: 12px !important;
	color: $black !important;
	@include font-bold;

	a {
		.no-touch &:hover {
			text-decoration: underline;
		}
	}
}
