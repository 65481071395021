/* ===========================================================
 Countdown Banner
=========================================================== */

.games .game {
    align-items: center;
min-height: 60px;
}

body.with-countdown {
padding-top: 80px;
}
.header.with-countdown {
top: 30px;
}

.with-countdown #menu-item-231 .sub-menu{
margin-top: 30px;
}

.with-countdown .hero{
margin-top:15px;
}

@media only screen and (max-width: 1249px){
.with-countdown .header__menus {
top: 90px;
}

.with-countdown #menu-item-231  .sub-menu{
margin-top: 0px;
}
.with-countdown .hero{
margin-top:0px;
}

}

.countdown{
width: 100%;
position: fixed;
top: 0;
left: 0;
right: 0;
z-index: 701;
}


/*
#clockdiv{

font-family: neuzeit-grotesk,Arial,Helvetica,sans-serif;
color: #fff;
display: inline-block;
font-weight: 700;
text-align: center;
font-size: 24px;
background-color: red;
width:100%;
}

#clockdiv > div{


display: inline-block;
margin: 2px 4px;
}

#clockdiv div > span{


display: inline-block;
}


.smalltext{
margin-left:1px;
font-size: 16px;
font-weight: 400;
display: inline;
}
*/