/* ===========================================================
  HOME
=========================================================== */

.home-content-wrap {
	position: relative;
	background: $white;
}

.featured--home {
	max-width: 100vw;
	margin: 0 auto 30px;

	.with-video & {
		margin-bottom: 0;
		padding-bottom: 40px;
		background: $white;
	}

	@include bp--xs{
		
		max-width: 80vw;
	}

	@include bp--sm {
		max-width: none;
	}
	

	@include bp--md {
		max-width: none;
		margin: 0 0 40px;
	}

	.container {
		display: flex;
		justify-content: space-between;
		align-items: stretch; //flex-start;
		flex-wrap: wrap;
		padding: 0 30px;
		

		
	}

	.container.featured--grid{
	//	border: 1px solid blue;
		display: flex;
		

	//	background-color: yellow;
		/* padding: 0 30px; */

		@include bp--xs{
			//background-color: lightgreen;
		}

		@include bp--sm {
			/*flex-wrap: nowrap;*/
		//	background-color: lightblue;
			display: grid;

			grid-template-columns: repeat(5, 1fr);
			grid-gap: 20px;
		}

		@include bp--md {
			/* flex-wrap: nowrap; */
			// background-color: orange;
		}

		@include bp--lg {
		//	background-color: pink;
			 padding: 0; 
		}
	}

	&__side {
		order: 3;
		width: 100%;
		margin: 0 auto 30px;

		&:not(.twitter-widget) {
			text-align: center;
		}
	
		@include bp--sm {
			order: 2;
			width: 50%;
			padding-right: 30px;
			text-align: left;
		}

		@include bp--md {
			order: 1;
			margin: 0 0 30px;
			width: auto;
			max-width: 285px;
			
			&:not(.twitter-widget) {
				padding-right: 0;
			}
		}
	}

	&__blog {
		/*order: 1;*/
		width: 100%;
	/*margin-bottom: 30px;*/
		grid-column-start: 1;
		grid-column-end: 4;
		grid-row-start: 1;
		grid-row-end: 2;
		order: 2;
		margin: 30px 0;

		@include bp--xs{

		}

		@include bp--sm{
			grid-column-start: 1;
			grid-column-end: 6;
			grid-row-start: 1;
			grid-row-end: 2;
			margin: 0;
		}

		@include bp--md {
			/*
			padding-left: 35px;
			padding-right: 35px;
			*/
			/*order: 2; */
			width: auto;
			grid-column-start: 1;
			grid-column-end: 4;
			grid-row-start: 1;
			grid-row-end: 2;
			/*margin-bottom: 0;*/
		}
	}

	&__headlines {
		grid-column-start: 1;
		grid-column-end: 4;
		grid-row-start: 2;
		grid-row-end: 3;
		order: 3;
		margin: 30px 0;

		@include bp--sm{

			grid-column-start: 1;
			grid-column-end: 3;
			grid-row-start: 2;
			grid-row-end: 3;
			margin: 0;
		}

		@include bp--md{
			grid-column-start: 1;
			grid-column-end: 4;
			grid-row-start: 2;
			grid-row-end: 3;
			
		}
	}

	&__upcoming {
		/*order: 2;*/
		width: 100%;
		order: 1;
		/* margin-bottom: 30px; */

		grid-column-start: 4;
		grid-column-end: 6;
		grid-row-start: 1;
		grid-row-end: 3;
		margin: 30px 0;

		@include bp--sm {
			/* order: 3;
			
			width: 35%;
			min-width: 300px;
			*/
			max-width: none;
			margin-bottom: 0;
			width: 100%;
			grid-column-start: 3;
			grid-column-end: 6;
			grid-row-start: 2;
			grid-row-end: 3;
			margin: 0;
		}

		@include bp--md{
			grid-column-start: 4;
			grid-column-end: 6;
			grid-row-start: 1;
			grid-row-end: 3;
		}
	}
}

