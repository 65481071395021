/* ===========================================================
  GAMES TABLE
=========================================================== */

.game-table {
	.game-row {
		display: flex;
		outline: 1px $gray solid;
		height: 60px;

		@include bp--xs{
			height: 80px;
		}
		
		& + .game-row {
			//border-top: 0;
		}

		.game-col {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 8px;

			@include bp--xs {
				padding: 15px;
			}

			&.game-title {
				width: 30%;
				border-right: 1px $gray solid;

				h5 {
					margin: 0;
					letter-spacing: 0;
					 color: #898989;

					@include bp--md(max) {
						@include rem(font-size, 17px);
					}

					a {
						color: $black;
						transition: color 0.3s ease;

						.no-touch &:hover {
							color: $red;
							transition: color 0.3s ease;
						}
					}
				}
			}

			&.game-teams {
				width: 100%;

				> * {
					width: 25%;
				}

				.team {
					position: relative;
					height: 100%;

					@include bp--md(max) {
						width: 40%;
					}

					&.with-record {
						padding-bottom: 15px;

						span {
							position: absolute;
							display: block;
							bottom: 0;
							left: 0;
							right: 0;
							color: rgba(0, 0, 0, 0.4);
							text-align: center;
							@include rem(font-size, 11px);
							@include rem(line-height, 11px);
						}
					}

					.team-logo {
						@include center-h--unk();

						img {
							display: block;
							margin: 0 auto;
							max-width: 70px;
							max-height: 50px;
							width: auto;
							height: auto;
							
							@include bp--md(max) {
								max-width: 55px;
								max-height: 35px;
							}
						}
					}
				}

				.vs {
					position: relative;
					height: 100%;

					@include bp--md(max) {
						width: 20%;
					}

					&::after {
						display: block;
						content: '';
						position: absolute;
						top: 50%;
						left: 15%;
						right: 15%;
						margin-top: -3px;
						width: 70%;
						height: 6px;
						background: $black;

						@include bp--md(max) {
							margin-top: -2px;
							height: 4px;
						}
					}
				}
			}
		}
	}

	&--widget {
		min-width: 234px;
		max-width: 100%; //300px;

		.sidebar & {
			min-width: 0;
		}
		
		@include bp--sm {
			max-width: none;
		}

		.game-row {
			padding: 0 5px;
			border-left: 0;
			border-right: 0;

			&:first-of-type {
				border-top: 0;
			}

			&:last-of-type {
				border-bottom: 0;
			}

			.game-col {
				padding: 10px 5px;

				&.game-title {
					border-right: 0;

					h5 {
						@include rem(font-size, 12px);
						@include rem(line-height, 20px);

						span {
							display: block;
							@include font-bold;
							@include rem(font-size, 11px);
							@include rem(line-height, 13px);
						}
					}
				}

				&.game-teams {
					.team {
						.team-logo {
							img {
								/*
								max-width: 50px;
								max-height: 35px;
								*/
							}
						}
					}

					.vs {
						&::after {
							margin-top: -2px;
							left: 25%;
							right: 25%;
							width: 50%;
							height: 4px;
						}
					}
				}
			}
		}
	}
}

/* ===========================================================
  SCHEDULE BLOCK - ARCHIVE
=========================================================== */

.schedule-blocks {
	margin: 0 auto;
	
	@include bp--sm {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.schedule-block {
		@include bp--sm {
			width: 48%;
		}

		@include bp--lg {
			width: 44%;
		}
	}
}

.schedule-block {
	position: relative;
	margin: 0 auto 72px;
	max-width: 350px;
	padding: 15px 0 0;
	border: 1px $gray solid;
	border-top: 10px $red solid;

	@include bp--sm {
		margin: 0 0 72px;
		max-width: none;
	}

	@include bp--lg {
		margin: 0 0 100px;
	}

	&--single {
		max-width: 500px;
		margin: 0 auto;
	}

	&__text {
		padding: 20px;
		text-align: center;

		h3 {
			margin: 0;
			color: $red;
			@include font-bold;

			a {
				transition: color 0.3s ease;

				.no-touch &:hover {
					color: $black;
					transition: color 0.3s ease;
				}
			}
		}

		h5 {
			color: $black;
			@include font-bold;
		}

		p {
			color: #898989;
			@include font-bold;
			@include rem(font-size, 13px);
			@include rem(line-height, 16px);

			&.box-score-cta {
				a {
					color: $blue;
					text-transform: uppercase;
					transition: color 0.3s ease;

					.no-touch &:hover {
						color: $black;
						transition: color 0.3s ease;
					}
				}
			}
		}
	}

	&__link {
		//position: absolute;
		//left: 0;
		//right: 0;
		//bottom: -42px;
		padding: 9px 0;
		text-align: center;

		a {
			text-transform: uppercase;
			transition: color 0.3s ease;
			@include font-bold;
			@include rem(font-size, 13px);
			@include rem(line-height, 13px);

			@include bp--sm {
				@include rem(font-size, 16px);
				@include rem(line-height, 16px);
			}

			.no-touch &:hover {
				color: $black;
				transition: color 0.3s ease;
			}
		}
	}

	.game-table {
		.game-row {
			border-left: 0;
			border-right: 0;

			&:last-child {
				border-bottom: 0;
			}
		}
	}

	&.past {
		border-top: 10px $blue solid;

		.schedule-block__text {
			h3,
			h3 a {
				color: $blue;

				.no-touch &:hover {
					color: $black;
					transition: color 0.3s ease;
				}
			}
		}

		.schedule-block__link {
			a {
				color: $blue;

				.no-touch &:hover {
					color: $black;
					transition: color 0.3s ease;
				}
			}
		}
	}

	.dayofweek{
		display: block;
		color: #b7b7b7;
		font-size: 2rem;
		line-height: 2rem;
		margin-bottom: 20px;
	}

	.tbd{
		padding: 60px 20px;
		text-align: center;
		background: #f6f6f6;
		margin: 20px;
		color: dimgray;
	}
}

/* ===========================================================
  SCHEDULE WIDGET – HOME, TEAM (SINGLE)
=========================================================== */

.schedule-widget {
	padding: 0;

	border-top: 10px $red solid;
	overflow: hidden;
	position: relative;

	.schedule-widget-inner{
		border: 1px $gray solid;
		padding: 15px 0 0;
		height: 100%;
	}
/*
	&::before{
		content:'';
		top:0;
		left:0;
		bottom:0;
		right:0;
		position: absolute;
		border: 1px $gray solid;
		
	}
	*/

	@include bp--md {
		max-width: 234px;

		&.full-width {
			max-width: none;
			width: 100%;
		}
	}

	&__logo {
		display: block;
		margin: 0 auto 10px;
	}

	&__text {
		text-align: center;

		h5 {
			margin: 0;
		}

		h6 {
			@include font-bold;
			@include rem(font-size, 15px);
			@include rem(line-height, 18px);

			span{
				color: #898989;
			}
		}

		p {
			margin: 0 0 10px;
			@include font-bold;
			@include rem(font-size, 11px);
			@include rem(line-height, 14px);
		}

		a {
			text-transform: uppercase;
			color: $black;
			transition: color 0.3s ease;
			@include font-black;
			@include rem(font-size, 13px);
			@include rem(line-height, 16px);

			.no-touch &:hover {
				color: $red;
				transition: color 0.3s ease;
			}
		}
	}
}

/* ===========================================================
  GAMES – TICKETS PAGE
=========================================================== */

.featured--games {
	padding: 25px 0;
	background: $gray-lt;

	&.margin {
		margin: 0 0 2em;
	}

	.slick-track {
		padding: 0 !important;
	}

	.slick-list {
		padding: 0 50px !important;
		margin: 0 -20px !important;

		@include bp--xs {
			padding: 0 75px !important;
		}

		@include bp--sm {
			padding: 0 !important;
			margin: 0 !important;
		}
	}

	&__wrap {
		opacity: 0;
		transition: opacity 0.4s ease;

		&.slick-initialized {
			opacity: 1;
		}

		@include bp--sm {
			margin: 0 -20px;
		}
	}

	&__game {
		position: relative;
		width: 33.33%;
		text-align: center;
		overflow: hidden;
		border-bottom: 1px solid #ebebeb;

		a:focus {
			outline: none;
		}
		
		h3 {
			color: $red;
			@include font-bold;

			@include bp--lg(max) {
				@include rem(font-size, 22px);
				@include rem(line-height, 22px);
			}
		}

		h5 {
			color: $black;
			@include font-bold;
		}

		p {
			margin: 0;
			text-transform: uppercase;
			color: $black;
			transition: opacity 0.3s ease;
			@include font-bold;
			@include rem(font-size, 13px);
			@include rem(line-height, 16px);
		}

		a {
			position: relative;
			display: block;
			height: 100%;
			background: $white;
			margin-left: 5px;
			margin-right: 5px;
			padding: 20px 10px 26px;
			border-bottom: 6px $red solid;
			overflow: hidden;

			@include bp--xs {
				margin-left: 10px;
				margin-right: 10px;
			}

			@include bp--lg {
				margin-left: 20px;
				margin-right: 20px;
			}

			.game-cta {
				display: block;
				position: absolute;
				top: auto;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 46px;
				background: $red;
				color: $white;
				text-align: center;
				text-transform: uppercase;
				transition: transform 0.35s ease;
				transform: translate3d(0, 100%, 0);

				span {
					display: block;
					padding: 15px;
					transform: translate3d(0, 200%, 0);
					transition: transform 0.3s ease;
					@include font-bold;
					@include rem(font-size, 20px);
					@include rem(line-height, 20px);
				}
			}

			.no-touch &:hover {
				p {
					opacity: 0;
					transition: opacity 0.3s ease;
				}

				.game-cta {
					transform: translate3d(0, 0, 0);

					span {
						transform: translate3d(0, 0, 0);
						transition-delay: 0.1s;
					}
				}
			}
		}

		&.past {
			h3 {
				color: $blue;
			}

			a {
				border-color: $blue;

				.game-cta {
					background: $blue;
				}
			}
		}
	}
}

.games {
	padding: 50px 0;
	text-align: center;


//	@include bp--sm {
	@include bp--md {
		text-align: left;
	}



	.game {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		align-items: flex-start;
		justify-content: space-between;
		margin-bottom: 10px;

		@include bp--md {
			border: 0;
		}
/*
		&:nth-child(odd){
			margin-top: 40px;
			padding-top: 40px;
			border-top: 6px #f6f6f6 solid;

			@include bp--md {
				margin-top: 60px;
				padding-top: 0;
				border-top: 0;
			}

		}
*/
		&:first-child{
			margin-top: 0;
			padding-top: 0;
			border-top: 0;
		}


/*
		& + .game {
			margin-top: 40px;
			padding-top: 40px;
			border-top: 6px #f6f6f6 solid;

			@include bp--md {
				margin-top: 60px;
				padding-top: 0;
				border-top: 0;
			}
		}
*/

		&__week {
			width: 100%;
			margin: 40px 0 40px;
			border-bottom: 1px $gray solid;
			padding-bottom: 10px;

/*
			@include bp--sm {
				width: 25%;
			}
*/
			@include bp--md {
				//width: 18%;
			}

			h3 {
				margin: 0 auto;
				text-align: center;
				@include font-bold;
				@include rem(line-height, 30px);
				

				span {
					display: block;
					color: $gray-md;
					@include rem(font-size, 20px);
					@include rem(line-height, 20px);
				}

				a {
					color: $black;
					transition: color 0.3s ease;

					.no-touch &:hover {
						color: $red;
						transition: color 0.3s ease;
					}
				}
			}
		}

		&__details{
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			width: 100%;
			justify-content: space-evenly;
			align-items: flex-start;
			
		}

		&__date {
		//	width: 100%;
			//padding-left: 10px;
			text-align: center;
			/*
			@include bp--sm {
				width: 30%;
				padding-right: 10px;
				text-align: left;
			}
*/
			@include bp--md {
			//	width: 20%;
			//	padding-right: 10px;
			//	text-align: left;
			}

			h4 {
				@include font-bold;
				@include rem(font-size, 20px);
				@include rem(line-height, 30px);
			}
		}

		&__location {
			//width: 100%;
			
			//@include bp--sm {
			@include bp--md {
				//width: 45%;
				//padding-left: 10px;
				//padding-right: 10px;
			}

			h4{
				@include rem(font-size, 20px);

				span {
					display: block;
					@include rem(font-size, 13px);
					line-height: 1rem;
				}
			}

			p {
				margin: 0;
				text-transform: uppercase;
				@include font-black;
				@include rem(font-size, 16px);
				@include rem(line-height, 18px);

				span {
					display: block;
					@include rem(font-size, 13px);
				}
			}
		}

		&__cta {
			@extend %clearfix;
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			margin: 0px auto 30px;

			//margin-top: 20px;

			width: 100%;
    		//margin: 20px auto;

			@include bp--md {
				//width: 17%;
				//margin-top: 0;
			}

			.button {
				width: 100%;
				margin: 10px;
				max-width: 250px;
/*
				@include bp--sm {
					max-width: 170px;
					float: right;
				}
*/
				@include bp--md {
					//max-width: 100%;
					float: none;
				}

				
			}

			//.button.btn-tickpick{
			.button.btn-viptickets{
				position: relative;

				
			//	text-indent: -9999px;  

				&::after{
					content:'';
					position: absolute;
					width: 100%;
					height: 100%;
					top:0;
					left: 0;
				//	background-image: url('../images/tickpick-logo-white.png');
			
					background-repeat: no-repeat;
					background-size: 62%;


					@include bp--xs {
						background-size: 41%;
					}


					background-repeat: no-repeat;
					background-position: 50% 50%;

				}

				
			}


		}

		&.game-group{
			margin-top: 40px;
		}

		&.past {
			h3,
			h4,
			p {
				color: $gray-dk;
			}
		}

		&.no-week {
			.game__date {
				padding-left: 0;
				text-align: left;

				@include bp--md {
					width: 25%;
				}
			}

			.game__location {
			/*	@include bp--sm {
					width: 50%;
					text-align: right;
				}
*/
				@include bp--md {
					text-align: left;
				}
			}

			.game__cta {
				@include bp--md {
					width: 25%;
				}
			}
		}
	}
}


.promo-banner{width:100%;margin:0;height:auto;position:fixed;z-index:10;background-color:#000}
 
.promo-banner h4{text-transform:uppercase;color:#fff;margin:0;text-align:center;font-size:20px;font-weight:600}

.single-fh-game main{padding-top:30px}

.game__msg{margin:20px 20%};

.game__msg p{text-align:center}

.game-img{position:relative;height:300px;width:100%;left:calc(-50vw + 50%)}

.city-bg:before{content:"";background-image:inherit;background-size:cover;width:100vw;background-repeat:no-repeat;height:300px;background-position:center 40%;position:absolute;left:0;top:0}

.cfx_form_inner{border:1px solid #000!important;padding:30px 30px 10px}

.cfx_form_contents{padding:0 30px 30px;min-width:360px}

.cfx_form_label{display:none!important}

.cfx_input{font-size:18px!important;width:100%}

.cfx_submit_wrap{width:100%;margin:20px 0 0;padding:0}

.cfx_submit{width:100%;margin:10px;max-width:250px;background:#e51937;display:inline-block;padding:12px 20px 10px;text-transform:uppercase;text-align:center;text-decoration:none;font-family:neuzeit-grotesk,Arial,Helvetica,sans-serif;font-weight:700;font-size:2rem;line-height:2rem;color:#fff;border:none;cursor:pointer}

.cfx_submit:hover{background:#000}

.cfx_form_div_1 .cfx_thanks_msg{border:1px solid #000!important;background-color:#fff!important;color:#000!important;padding:50px;font-size:16px;font-style:normal;font-weight:400;display:none;position:absolute;top:0;left:0;width:100%;height:100%;text-align:center}

.cfx_form_div_1 .cfx_thanks_msg>div{display:flex;height:100%;align-items:center;justify-content:center;padding:0 40px;flex-direction:column}

.cfx_form_div_1 .cfx_thanks_msg>div strong{display:contents}

.cfx_form_div_1 .cfx_thanks_msg>div a{margin-top:20px;width:100%}

.cfx_close_popup_btn{z-index:10}