/* ===========================================================
  VIDEO PLAYLIST
=========================================================== */

.big3-yt-playlist {

    .yt-container{
            margin: 40px 0;
           
            background-color: #012b5d;
            min-height: 250px;
            width: 100%;
            position: relative;
          

            @media only screen and (max-width: 600px){
        
             //   background-color: lightblue;
              
        
            }
        


        .yotu-playlist > div{
            display: flex;
            flex-direction: row;

            @media only screen and (max-width: 600px){
                flex-direction: column;
            }

            @media only screen and (min-width: 1250px){
              
                padding: 30px;
            }

            .yotu-wrapper-player{
                width: 60% !important;
              //  height: calc(60vw * 0.5625);
              //  border: 1px solid yellow;


                height: calc(60vw * 0.5625 + 38px);

                display: flex;
                flex-direction: column-reverse;


                @media only screen and (max-width: 600px){
                    width: 100% !important;
                    height: auto !important;
                }

                @media only screen and (min-width: 1250px){
                    height: calc(60vw * .5625 + 15px);
                }

                
            }

            .yotu-playing{
                text-align: left;
                font-weight: 500;
                font-size: 15px;
  
                padding: 8px 15px;
                height: 40px;
                background-color: #e51937;
                color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 600;
                text-transform: uppercase;
                font-size: 15px;
                line-height: 15px;

            }

            .yotu-videos{
                width: 40%;
            //    border: 1px solid #4CAF50;
             //   height: calc(60vw * 0.5625 - 40px);
                height: calc(60vw * 0.5625 );
                overflow: scroll;
                padding: 0 0 0 32px;
                margin: 0 20px 0 0;

                


                @media only screen and (max-width: 600px){
                    width: 100% !important;
                    height: auto !important;
                    margin: 0;
                    padding: 20px 40px;
                    max-height: 380px;
                }

                @media only screen and (min-width: 1250px){
                    height: calc(60vw * .5625 - 20px);
                    margin: 0 !important;
                }

                


                ul{

                    display: flex;
                    flex-direction: column;

                    @media only screen and (max-width: 600px){
                        margin-top: 30px;
                    }

                    li{
                        width: 100% !important;
                        margin: 0 !important;

                        .yotu-video-description{
                            display: none;
                        }
                    }

                    li.yotu-first{
                        margin-top: 0 !important;
                    }

                    li a[data-title="Private video"] {
                        display: none;
                      }
                }

                .yotu-video{
                    padding: 10px 0px;
                    transition: background 0.3s ease-out;


                   &:hover{
                  
                    background-color: rgba(255,255,255,0.1);
                   }
                }

                .yotu-video-title{
                    color: white;
                    margin-top: 4px !important;
                    font-weight: 600;
                    text-transform: uppercase;
                    font-size: 13px;
                    line-height: 18px;
                }
            }
        }

        a.yt-button{
            position: absolute;
            right: 0;
            width: 40%;
            z-index: 10;
          


            bottom: 0;
            right: 0;
            padding: 0;
            padding: 0 20px;
            margin: 0 !important;
            margin-bottom: 30px !important;

            @media only screen and (max-width: 600px){
                position: relative;
                width: 100%;
                bottom:30px;
              
                padding: 0 20px;
                margin: 0 !important;
              //  margin: 20px 30px !important;
            }

            @media only screen and (min-width: 1250px){
               // height: calc(60vw * .5625 - 20px);
            /*   bottom: 30px;
               right: 30px;
               width: calc(40% - 25px);
*/
               bottom: 30px;
               right: 30px;
               width: calc(40% - 55px);
               padding: 0;
            }

            
            .yt-button-inner{
                color: white;
                border:1px solid white;
                font-weight: 600;
                text-align: center;
                height: 40px;
                transition: all .3s ease-out;
                display: flex;
                justify-content: center;
                align-items: center;
                text-transform: uppercase;
                background-color: #052b5d;

                @media only screen and (max-width: 600px){
                  
                    margin: 20px 30px !important;
                }
            }

            &:hover{
               

                .yt-button-inner{
                    color: #e51937;
                    background-color: white;
                }
            }
        }
    }

	
}
/*
@media only screen and (min-width: 768px) and (max-width: 999px) {
    body .yotu-mode-grid li, .yotu-mode-mix li, body .yotu-mode-grid[class*=' yotu-column-'] li, body .yotu-mode-grid[class^='yotu-column-'] li {
        width: 100% !important;
        margin-right: 0 !important;
    }
}
    */

