/* ===========================================================
  YOUNG3
=========================================================== */

.y3-content{

    margin-top: -180px;
    z-index: 2;

    @media only screen and (max-width: 600px){  
        margin-top: -80px;
    }

    .main{
        margin: 0 auto;

        .y3-logo-main{

            img{
                margin: 0 auto;
                width: 80%;
                display: block;
                max-width: 600px;
            }
        }

        h1, h2{
            text-transform: uppercase;
            text-align: center;
            margin: 60px 0 30px;
            font-size: 5rem;
            line-height: 5rem;
        }

        p{
            max-width: 600px;
            margin: 0 auto;
        }
    }
}

.y3-grid {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  margin: 80px 60px;



  @media only screen and (max-width: 600px){  
    grid-template-columns: 1fr;
    margin: 60px 20px; 
        
  }
}
.y3-event {
  border: 5px solid #dbbb61;
  background: rgb(4,80,168);
  background: radial-gradient(circle, rgba(4,80,168,1) 0%, rgba(0,36,68,1) 100%);
  padding: 1rem;
  text-align: center;
  border-radius: 30px;
  padding: 2rem 1rem;

  h4{
    color: white;
    font-size: 42px;
    line-height: 48px;
    margin: 0;

    @media only screen and (min-width: 600px) and (max-width: 840px){  
        font-size: 36px;
    }
  } 

  h5{
    color: #dbbb61;
  }

  h6{
    color: lightgrey;
    font-size: 1.2rem;
    line-height: 1.5rem;
    font-weight: auto;
  }
}

.y3-cta{
    max-width: 600px;
    margin: 40px auto 80px;

    a{
        text-decoration: none;

        &:hover{

            .y3-cta-box{
                background-color: #082e62;
            }

        }

        .y3-cta-box{
            padding: 60px 10%;
            background-color: #002444;
            border-top: 10px solid #e51937;
            position: relative;

            @media only screen and (max-width: 600px){  
             padding: 60px 5%;
            }

            img.y3-logo{
                position: absolute;
                left: 50%;
                top: 0;
                width: 200px;
                transform: translate(-50%, -70%);
            }


            h6{
                color: white;
                text-align: center;
                font-size: 3rem;
             

                span{
                    display: block;
                    color: #dbbb61;
                    text-decoration: underline;
                    font-size: 1.6rem;
                }
            }
        }
    }
}

.y3-cta-banner{
    max-width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    transform: translateY(100%);
    transition: transform 0.3s ease-in-out;

    &.shown{
        transform: translateY(0%);
    }
 

    a{
        color: #e51937;

        &:hover{

            .y3-cta-box{
                background-color: #082e62;
            }

        }

        .y3-cta-box{
            padding: 15px 5%;
            background-color: #002444;
         //   border-top: 10px solid #e51937;
            position: relative;

            display: flex;
            justify-content: center;
            align-items: center;

            @media only screen and (max-width: 600px){  
                hegiht: 90px;
            }



            img.y3-logo{

                height: 34px;
                transform: translate(0px, -3px);
                margin-right: 20px;
          
            }


            h6{
                color: white;
                text-align: center;
                text-decoration: none;

                @media only screen and (max-width: 600px){  
                    line-height: 1.7rem;
                }

                span{
                    color: #dbbb61;
                    text-decoration: underline;
                }
            }
        }
    }
}




.y3-gallery {
    background: #ffffff;
    margin: 20px 0 60px;
  }
  
  .gallery-cell {
    width: 80%;
    height: 500px;
    margin-right: 10px;
    background-position: center 40%;
    counter-increment: gallery-cell;
 
  }
  
  .gallery-cell.is-selected {
    //background: #ED2;
  }
  
.flickity-page-dots{
    padding: 20px 0;
    margin: 0 !important;
    bottom: -30px;
}
.flickity-prev-next-button{
    display: none;
}