/* ===========================================================
   BASE
=========================================================== */

* {
	margin: 0;
	padding: 0;
	box-sizing: inherit;

	&::before,
	&::after {
		box-sizing: inherit;
	}
}

html {
	min-width: 320px;
	min-height: 100%;
	font-size: 62.5%;
	box-sizing: border-box;
	overflow-x: hidden;
}

body {
	position: relative;
	padding-top: $header-height;
	min-width: 320px;
	width: 100%;
	min-height: 100%;
	background-color: $background;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-family: $font-def;
	color: $font-color;
	@include rem(font-size, $font-size);
	@include rem(line-height, $line-height);

	&.home,
	&.blog,
	&.category {
		overflow-x: hidden;
	}

	&.fixed {
		position: fixed;
		overflow: hidden;

		@include bp--lg {
			position: relative;
			overflow: auto;
		}
	}
/*
	&.with-stats {
		padding-top: $full-header-height;
	}
*/
	&.is-loading {
		position: fixed;
		overflow: hidden;
		padding-top: 0;
	}
}

img {
	max-width: 100%;
	height: auto;
}

.container {
	max-width: $page-width;
	@include push-auto;

	&--large {
		max-width: $page-width-lg;
	}

	&--medium {
		max-width: $page-width-md;
	}

	&--small {
		max-width: $page-width-sm;
	}

	&--xsmall {
		max-width: $page-width-xs;
	}

	&--padding {
		padding: 0 20px;

		@include bp--md {
			padding: 0 30px;
		}

		@include bp--lg {
			padding: 0;
		}
	}
}

[data-animation-in] {
	opacity: 0;
}

