/* ===========================================================
  CONTENT
=========================================================== */

.content {
	position: relative;
	padding: 30px 0;

	&.no-padding-top {
		padding-top: 0;
	}

	&.no-padding {
		padding: 0;
	}

	&.with-sidebar:not(.no-bg) {
		margin-bottom: 30px;
		padding: 0;
		border-bottom: 1px $gray-lt solid;

		@include bp--lg {
			&::before {
				display: block;
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 50%;
				z-index: 1;
				background: $gray-lt;
			}

			.container {
				position: relative;
				z-index: 2;
			}
		}
	}

	&--image {
		position: relative;

		@include bp--sm {
			padding-top: 60px;
		}

		&__bg {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			z-index: 1;
			background-size: cover;
			background-position: top center;
			height: 435px;
		}

		.container {
			position: relative;
			z-index: 2;

			main {
				background: $white;
			}
		}
	}

	.page-template-tpl-iframe & {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		height: 100%;
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
		padding-top: $header-height;
		padding-bottom: 0;

		@include bp--md {
			padding-bottom: 121px;
		}

		iframe {
			width: 100%;
			height: 100%;
		}
	}

	.page-template-tpl-iframe.with-stats &,
	.page-template-tpl-iframe.with-video & {
		padding-top: $full-header-height;
	}
}

.content--logo {
	padding: 20px 0;

	@include bp--sm {
		padding: 20px 0 50px;
	}

	img {
		display: block;
		margin: 0 auto;
		width: 100%;
		max-width: 150px;
		height: auto;

		@include bp--sm {
			max-width: 215px;
		}
	}
}
