/* ===========================================================
  RECAPS
=========================================================== */

.recap {
	display: inline-block;
	max-width: 440px;
	margin-bottom: 1.5em;

	&__image {
		position: relative;
		background: $black;
		overflow: hidden;

		img {
			display: block;
			opacity: 0.7;
			transition: all 0.3s ease;
		}

		.watch {
			position: absolute;
			left: 0;
			bottom: 0;
			right: 0;
			display: flex;
			align-items: center;
			padding: 10px;

			span {
				display: inline-block;

				&.play {
					position: relative;
					width: 40px;
					height: 30px;

					&::before {
						display: block;
						content: '';
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 10px 0 10px 16px;
						border-color: transparent transparent transparent #fff;
						@include center-both;
					}
				}

				&.text {
					color: $white;
					text-transform: uppercase;
					@include font-black;
					@include rem(font-size, 15px);
					@include rem(line-height, 15px);
				}
			}
		}
	}

	h6 {
		padding-top: 10px;
		text-transform: none;
		transition: color 0.3s ease;
		@include font-bold;
	}

	.no-touch &:hover {
		.recap__image {
			img {
				opacity: 1;
				transform: scale(1.05);
				transition: all 0.3s ease;
			}
		}

		h6 {
			color: $red;
			text-decoration: underline;
			transition: color 0.3s ease;
		}
	}
}

.recaps--latest {
	margin: 2.5em 0 1.5em;
	padding-top: 20px;
	border-top: 6px $black solid;

	@include bp--sm {
		border-width: 12px;
	}

	> h6 {
		margin-bottom: 20px;
	}

	&__wrap {
		display: flex;
		flex-wrap: wrap;

		.recap {
			@include bp--sm {
				width: 32%;

				& + .recap {
					margin-left: 2%;
				}
			}
		}
	}
}

.recap-slides {
	opacity: 0;
	display: flex;
	transition: opacity 0.4s ease;

	&.ready {
		opacity: 1;
		display: block;
	}

	.recap-slide {
		position: relative;
		padding: 15px;
		text-align: center;

		@include bp--sm {
			padding: 25px 20px;
		}

		.recap-slide-wrap {
			position: relative;
			display: block;

			&:focus {
				outline: none;
			}
		}
		
		&:focus {
			outline: none;
		}

		h3 {
			color: $red;
			@include font-bold;
			@include rem(font-size, 28px);
			@include rem(line-height, 28px);

			@include bp--lg(max) {
				@include rem(font-size, 25px);
				@include rem(line-height, 25px);
			}

			@include bp--sm(max) {
				@include rem(font-size, 16px);
				@include rem(line-height, 16px);
			}
		}

		h5 {
			color: $black;
			@include font-bold;
			@include rem(line-height, 20px);

			@include bp--lg(max) {
				@include rem(font-size, 18px);
				@include rem(line-height, 18px);
			}

			@include bp--sm(max) {
				@include rem(font-size, 12px);
				@include rem(line-height, 12px);
			}
		}

		a {
			h3 {
				color: $black;
				transition: color 0.3s ease;
			}

			h5 {
				color: $black;
			}

			.no-touch &:hover {
				h3 {
					color: $red;
					transition: color 0.3s ease;
				}
			}
		}

		&.current-page {
			padding: 13px 15px 17px;
			background-image: url('../images/indicator--red.png');
			background-size: 10px auto;
			background-position: 50% 100%;
			background-repeat: no-repeat;
			
			@include bp--sm {
				padding: 18px 20px 32px;
				background-position: 50% 80%;
			}

			h5 {
				color: $red;
			}

			a {
				h5 {
					color: $red;
				}
			}
		}
	}
}

.recap__nav {
	position: relative;
	padding: 0 25px;
}

.recap__arrows {
	margin: 0;
	padding: 0;
	list-style: none;

	li {
		a {
			position: absolute;
			top: 0;
			bottom: 0;
			width: 25px;

			svg {
				position: absolute;
				top: 50%;
				left: 50%;
				margin-top: -5px;
				margin-left: -5px;
				width: 10px;
				height: 10px;
				fill: $gray-dk;
				transition: fill 0.3s ease;
			}

			&.previous {
				left: 0;

				svg {
					@include rotate(180);
				}
			}

			&.next {
				right: 0;
			}

			.no-touch &:not(.slick-disabled):hover {
				svg {
					fill: $red;
					transition: fill 0.3s ease;
				}
			}

			&.slick-disabled {
				opacity: 0.2;
				pointer-events: none;
			}
		}
	}
}

.video-link {
	display: inline-block;
	transition: background 0.3s ease;

	&__inner {
		display: flex;
		align-items: stretch;
	}

	.play,
	.text {
		display: inline-block;
		border: 4px $white solid;
	}

	.play {
		position: relative;
		width: 54px;
		height: 48px;

		&::before {
			display: block;
			content: '';
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 11.5px 0 11.5px 20px;
			border-color: transparent transparent transparent #fff;
			@include center-both;
		}
	}

	.text {
		border-left: 0;
		padding: 10px 15px;
		text-transform: uppercase;
		@include font-black;
		@include rem(font-size, 20px);
		@include rem(line-height, 20px);
	}

	.no-touch &:hover {
		background: rgba(255, 255, 255, 0.2);
		transition: background 0.3s ease;
	}
}

.recap--featured {
	position: relative;
	overflow: hidden;
	max-height: 650px;
	background: #252525;

	&.with-video {
		max-height: none;
	}

	&__image {
		img {
			display: block;
			width: 100%;
			height: auto;
			opacity: 0.6;
		}
	}

	&__text {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 199;
		transition: opacity 0.3s ease, visibility 0.4s ease;

		* {
			color: $white;
		}

		&.video-played {
			opacity: 0;
			visibility: hidden;
			transition: opacity 0.3s ease 0.4s, visibility 0.4s ease;
		}
	}

	&__title {
		padding: 20px;

		@include bp--sm {
			padding: 30px;
		}

		@include bp--lg {
			padding: 40px 0;
		}

		h3 {
			text-transform: none;
			@include font-bold;

			@include bp--sm(max) {
				@include rem(font-size, 25px);
				@include rem(line-height, 25px);
			}
		}

		&.with-video {
			@include bp--sm {
				display: flex;
				align-items: center;
			}

			h3 {
				margin-left: 20px;
				order: 2;

				@include bp--sm(max) {
					margin-bottom: 20px;
					margin-left: 0;
					order: 1;
				}
			}
		}
	}

	&__video {
		position: relative;

		.video-bg {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			opacity: 0.6;
			background-size: cover;
			background-position: top center;
			background-repeat: no-repeat;
		}

		.video-wrap {
			opacity: 0;
			visibility: hidden;
			transition: visibility 0.1s ease, opacity 0.3s ease;

			&.video-played {
				opacity: 1;
				visibility: visible;
				transition-delay: 0.4s;
			}
		}
	}
}

