/* ===========================================================
  MERCH – CTA
=========================================================== */

.merch--cta {
	margin: 30px 0;

	@include bp--sm {
		margin: 50px 0 70px;
	}

	&__wrap {
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		
		.merch-image {
			width: 100%;
			order: 1;

			@include bp--sm {
				width: 58%;
				order: 2;
			}

			@include bp--md {
				width: 58%;
			}
		}

		.merch-info {
			width: 100%;
			order: 2;
			padding-top: 10px;
			padding-right: 20px;

			@include bp--sm {
				width: 42%;
				order: 1;
				padding-top: 20px;
			}

			@include bp--md {
				padding-top: 80px;
			}

			h6 {
				display: none;
				margin-bottom: 20px;

				@include bp--sm {
					display: block;
				}
			}

			h2 {
				text-transform: uppercase;
				@include rem(font-size, 24px);
				@include rem(line-height, 30px);
					
				@include bp--sm {
					@include rem(font-size, 30px);
					@include rem(line-height, 36px);
				}

				@include bp--md {
					@include rem(font-size, 40px);
					@include rem(line-height, 48px);
				}
			}

			a {
				color: $black;
				transition: color 0.3s ease;

				.no-touch &:hover {
					color: $red;
					transition: color 0.3s ease;
				}
			}
		}

		.merch-link {
			display: block;
			width: 100%;
			order: 3;
			margin-top: 20px;

			@include bp--sm {
				margin-top: 0;
			}

			@include bp--md {
				margin-top: -50px;
			}
		}
	}
}

/* ===========================================================
  FEATURED MERCH – HOME
=========================================================== */

.merch--featured {
	position: relative;
	padding: 0 0 30px;

	@include bp--sm {
		padding: 30px 0;
	}

	@include bp--lg {
		padding: 100px 0 50px;
	}

	.merch-slides-wrap {
		margin: 0 -50%;

		@include bp--xs {
			margin: 0 -25%;
		}

		@include bp--sm {
			margin: 0 -15%;
		}

		.slick-list {
			padding-top: 50px !important;
		}
	}

	.merch-slides {
		.slick-slide {
			height: 350px;
			z-index: 3;

			@include bp--sm {
				height: 500px;
			}

			&:focus,
			> div:focus,
			.merch-slide:focus,
			a:focus {
				outline: none;
			}

			> div,
			.merch-slide {
				height: 100%;
			}

			a {
				display: block;
				@include center-h--unk();

				&::after {
					position: absolute;
					z-index: 2;
					top: 15%;
					left: 0;
					right: 0;
					bottom: 15%;
					display: block;
					content: '';
					opacity: 1;
					border: 10px $white solid;
					transition: border-color 0.3s ease;

					@include bp--sm {
						border-width: 15px;
					}

					@include bp--lg {
						left: 0;
						right: 0;
						border-width: 20px;
					}

					@include bp-custom(1600px) {
						left: 50px;
						right: 50px;
					}
				}

				.no-touch &:hover {
					span {
						color: $red;
						transition: color 0.3s ease;
					}

					img {
						transform: scale(1.08);
						transition: all 0.3s ease;
					}
				}
			}

			img {
				position: relative;
				z-index: 3;
				display: block;
				margin: 0 auto;
				max-width: 130px;
				max-height: 300px;
				width: auto;
				height: auto;
				opacity: 0.5;
				transition: all 0.3s ease;

				@include bp--xs {
					max-width: 150px;
				}

				@include bp--sm {
					max-width: 200px;
					max-height: 450px;
				}

				@include bp--lg {
					opacity: 1;
				}
			}

			span {
				position: absolute;
				top: -50px;
				left: 0;
				right: 0;
				text-align: center;
				text-transform: uppercase;
				color: $black;
				transition: all 0.3s ease;
				opacity: 0;
				@include font-black;
				@include rem(font-size, 20px);
				@include rem(line-height, 20px);
			}

			&.slick-current {
				z-index: 4;

				a {
					&::after {
						border-color: $gray;
						transition: border-color 0.3s ease;
					}
				}

				img {
					max-width: 180px;
					opacity: 1;

					@include bp--xs {
						max-width: 230px;
					}

					@include bp--sm {
						max-width: 320px;
					}
				}

				span {
					opacity: 1;
					transition: opacity 0.3s ease;
				}
			}
		}

		&__arrows {
			height: 0;

			li {
				a {
					display: block;
					position: absolute;
					top: 50%;
					margin-top: -20px;
					width: 40px;
					height: 40px;
					border-radius: 50%;
					background: $black;
					transition: background 0.3s ease;

					@include bp--sm {
						margin-top: -38px;
						width: 76px;
						height: 76px;
					}

					.svg-wrap {
						display: block;
						@include center-h--unk();
					}

					svg {
						margin: 0 auto;
						display: block;
						width: 10px;
						height: 12px;
						fill: $white;

						@include bp--sm {
							width: 20px;
							height: 23px;
						}
					}

					&.previous {
						left: 15px;

						@include bp--sm {
							left: 35px;
						}

						svg {
							@include rotate(180);
						}
					}

					&.next {
						right: 15px;

						@include bp--sm {
							right: 35px;
						}
					}

					.no-touch &:hover {
						background: $red;
						transition: background 0.3s ease;
					}
				}
			}
		}

		&__link {
			text-align: center;

			a {
				display: inline-block;
				padding: 0 0 7px;
				color: $black;
				border-bottom: 12px $black solid;
				transition: all 0.3s ease;

				.no-touch &:hover {
					color: $red;
					border-color: $red;
					transition: all 0.3s ease;
				}
			}
		}
	}
}

