/* ===========================================================
  LANDER
=========================================================== */

.lander {
	text-align: center;

	+ .locations{
		padding: 25px 0;
		background: #f6f6f6;
	}

	h1.page-title {
		text-transform: uppercase;
		@include rem(font-size, 40px);
		@include rem(line-height, 40px);

		@include bp--sm {
			@include rem(font-size, 50px);
			@include rem(line-height, 50px);
		}

		@include bp--lg {
			@include rem(font-size, 60px);
			@include rem(line-height, 60px);
		}
	}

	.games{
		//border: 1px solid black;
	}

	.games .game{
		flex-wrap: none!important;
		align-items: flex-start;
		margin: 40px!important;
	}

	.game__date h4{
		text-align: center;
		margin:0 auto;
		line-height: inherit!important;
	}
	.game__city{
		margin:0 auto;
	}

	.game__city h4{
		color: #e51937;
		line-height: inherit;
		text-align: center;
		margin:0 auto 5px;
	}

	.game__location{
		width: 250px;
	}

    .game__location span{
		font-weight: 100;
		text-transform: capitalize;
		display: block;
    	font-size: 1.3rem;
		line-height: normal;
	}

	.game__city{
		//width: 50%;
	}

	@media only screen and (min-width: 64em){
		.games .game__location{
			width: 250px;
		}

		.games .game.no-week .game__date{
			width: auto;
		}

		.game__date h4{
			text-align: left;
			margin:0;
		}

		.game__city{
			margin:0;
		}

		.game__city h4{
			color: #e51937;
			line-height: inherit;
			text-align: left;
			margin:0;
		}
	}

}
