/* ===========================================================
  CONTACT
=========================================================== */

.contact-soon {
	&__pic {
		background-image: url('../images/bkgd-s3-hoop.png');
	}
}

.contact {
	margin: 0 0 1em;

	@include bp--md {
		margin: 0 0 2em;
	}

	&__pic {
		background-size: cover;
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-image: url('../images/bg--contact.jpg');
		height: 200px;

		@include bp--xs {
			height: 300px;
		}

		@include bp--md {
			background-position: 100% 0;
			height: auto;
		}
	}

	&__form {
		padding: 20px;

		@include bp--sm {
			padding: 40px;
		}

		@include bp--lg {
			padding: 45px 100px 150px;
		}

		h2 {
			text-transform: none;
			letter-spacing: none;
			@include rem(font-size, 35px);
			@include rem(line-height, 42px);

			@include bp--sm {
				@include rem(font-size, 50px);
				@include rem(line-height, 60px);
			}

			@include bp--lg {
				@include rem(font-size, 70px);
				@include rem(line-height, 80px);
			}
		}

		textarea {
			@include bp--sm {
				margin-top: 1em;
			}
		}

		.submit-wrapper {
			margin-top: 1em;
		}
	}
}
