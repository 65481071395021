/* ===========================================================
  PLAYER OF THE WEEK 
=========================================================== */



.pow {
	text-align: center;
    background-image: url('../images/pow/red-bkgd.jpg');
    background-size: cover;
    min-height: 100vh;
    color: white;
    padding: 0;
    overflow: hidden;
    

    * {
        box-sizing: border-box;
      }
      
      button {
        cursor: pointer;
        padding: 8px 16px;
        margin: 10px 5px;

           animation-delay: 1s;
      }
      
      main {
        display: flex;
        position: relative;
        flex-direction: column;
        width: 100vw;
        height: 100vh;
        justify-content: center;
        align-items: center;
        margin: 0;
        padding: 0;

        p{
            max-width: 550px;
            margin: 40px auto;

      
           animation-delay: .5s;
        }
      }

      .mpow-logo{
        max-width: 500px;
        width: 90%;


       // animation: fadein ease-out .5s;
       // animation-delay: 4s;
      }

      



      .action-group{
        position: absolute;
        top: 0;
        left:0;
        width: 100vw;
        height: 100vh;
        overflow: hidden;
       // border: 3px solid green;

      }

      .slide-group{
        position: absolute;
        top: 0;
        left:0;
        width: 100vw;
        height: 100vh;
        //opacity: .4;

        #backButton{
          position: absolute;
          left: 10px; 
          top: 20px;
        }

        .cta {

          padding-left: 65px;
          color: $red;
          letter-spacing: 0.025em;
          text-transform: uppercase;
    
          transform: translate3d(50%, 50%, 0);
          transition: opacity 0.35s, transform 0.35s;
          @include font-black;
          @include rem(font-size, 16px);
          @include rem(line-height, 16px);
    
          &::before {
            display: block;
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            margin-top: -1.5px;
            width: 50px;
            height: 3px;
            background: $red;
          }
    
          @include bp--sm {
            display: inline-block;
          }
        }
        
      }

      .title-group{
       // position: relative;
      //  width: 100vw;
      //  height: 90vh;
       // border: 10px solid red;
       // padding-bottom: 10vh;

        p{
          font-size: 1.6rem;
          line-height: 2.4rem;
          max-width: 500px;
          margin: 40px auto;
          animation-delay: .5s;
        }

        #sponsorButton{
          font-size: 14px;
        }

        a.button--red{
          display: block;
          margin: 10px auto;
          max-width: 70%;
        }
      }
      
      .controls {
        padding: 10px;  
        display: flex;
        align-items: center;
        justify-content: center;
        height: 70px;
        min-height: 70px;

        a{
          width: 40px;
          height: 40px;
        }

        a.next{
          position: absolute;
          top: 40%;
          right: 5%;

          display: block;
          opacity: 1;
          //transition: opacity .3s ease;
          cursor: pointer;
/*
          &:hover{
            opacity: .6;
          }
          */
        }
        a.previous{
          position: absolute;
          top: 40%;
          left: 5%;
          transform: scaleX(-1);

          display: block;
         // transition: opacity .3s ease;
          cursor: pointer;
/*
          &:hover{
            opacity: .6;
          }
          */
        }

         a svg {

          width: 40px;
          height: 40px;
          fill: #ffffff;
          transition: fill .3s;
        }
      }
      
      .slides-container {
        position: relative;
        overflow: hidden;  
        display: flex;
        flex: 1;
        width: 100vw;
        height: 100vh;
      }
      
      .slide {
        position: absolute;
        font-size: 90px;
        font-weight: 700;
        color: rgba(255,255,255,0.9);
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        overflow: hidden;
        
        width: calc(100% / 3);
        width: 100%;
      }
      
      .slides-inner {
        position: relative;
        height: 100%;
        width: 100%;  
        overflow: hidden;


        .feature-photo{
          position: absolute;
          width: 60%;
          top: 50%;
          height: 80%;
          max-height: 600px;
          left: calc(50% + 125px);
          transform: translate(-50%,-55%);
          z-index: -1;

          img{
            width: 850px;
          }
        }

       

       

        
      }

      .detail-box{

        position: absolute;
         /// width: 30%;
          top: 60%;
         // height: 80%;
          left: 26%;
          transform: translate(-50%,-50%);
          width: 220px;

          h2{
            color: white;
            text-align: left;
            text-transform: uppercase;
            margin: 0;
            text-shadow: 1px 1px 6px rgba(0,0,0, .5);

          }

          .stat{
            text-transform: uppercase;
            color: #E51C38;
            font-size: 32px;
            text-align: left;
            line-height: 30px;
          }

          .statistic{
            display: inline;
            color: white;
            margin-right: 2px;
          }

        .head-photo{
          
         
          margin: 2px 10px 10px;
          background-color: #e51c38;
          padding: 4px;
      
        }

        .head-img{

          & img{
            background-color: #d1ced3;
            padding-top: 20px;
            max-height: 230px;
          }

        }

        .head-title{
          font-size: 14px;
          font-weight: bold;
          text-transform: uppercase;
          letter-spacing: -0.25px;
          padding-bottom: 2px;
         // padding: 10px 0 6px;
          display: flex;
          align-items: center;

          .head-name{
            width: 100%;
            flex: 1;
          }
        }

        .team-logo{
          width :50px;
          height: 50px;
          background-color: white;
          background-size: 92%;
          background-repeat: no-repeat;
          background-position: 50% 50%;
        }

        .team-35417,
.team-3-headed-monsters {
	background-image: url('../images/svg/teams/3hmonsters-1.svg');
}

.team-35416,
.team-3s-company {
	background-image: url('../images/svg/teams/3scompany-1.svg');
}

.team-35418,
.team-ball-hogs{
	background-image: url('../images/svg/teams/ballhogs-1.svg');
}

.team-35419,
.team-ghost-ballers {
	background-image: url('../images/svg/teams/ghostballers-1.svg');
}

.team-35427,
.team-killer3s {
	background-image: url('../images/svg/teams/killer3s-1.svg');
}

.team-35435,
.team-power {
	background-image: url('../images/svg/teams/power-1.svg');
}

.team-35436,
.team-tristate {
	background-image: url('../images/svg/teams/tristate-1.svg');
}

.team-35434,
.team-trilogy {
	background-image: url('../images/svg/teams/trilogy-1.svg');
}

.team-40994,
.team-triplets {
	background-image: url('https://big3.com/wp-content/uploads/2019/01/Triplets-2.svg');
}

.team-40993, 
.team-enemies {
	background-image: url('https://big3.com/wp-content/uploads/2019/02/Enemies2.svg');
}

.team-40991,
.team-aliens {
	background-image: url('https://big3.com/wp-content/uploads/2019/01/Aliens.svg');
}

.team-40992,
.team-bivouac {
	background-image: url('https://big3.com/wp-content/uploads/2019/02/Bivouac300x300.svg');
}
      }

      .action-img{
       // position: absolute;
       // left: -500px;
       // top: 30%;
        position: absolute;
        top: 0;
        left: 0;
      }
/*
      #action1{
        left: -500px;
      }
      
      */

      .slide .candidate-grid{

        display: grid;
        grid-template-columns: 350px 350px;
        grid-row: auto auto;
        grid-column-gap: 0px;
        grid-row-gap: 0px;

        .detail-box{

          display: flex;
          flex-direction: row;
          position: relative;
          top: unset;
          left: unset;
          width: auto;
          transform: translate(0,0);
          /* width: 50%; */
          justify-content: space-around;
          align-items: flex-start;
         

          &:nth-child(odd) {
            flex-direction: row-reverse;
          }

        }

        .stat-box{
          //width: 25%;

          .stat{
            font-size: 22px;
            line-height: 22px;
          }
        }


      }


  .detail-box.winner{

    .winner-title{

      font-size: 36px;
      text-align: left;
      margin-bottom: 12px;
    }
    .head-photo{
          
         
      margin: 2px 0px 10px;
     
  
    }
  }
    

	h1.page-title {
		text-transform: uppercase;
		@include rem(font-size, 40px);
		@include rem(line-height, 40px);

		@include bp--sm {
			@include rem(font-size, 50px);
			@include rem(line-height, 50px);
		}

		@include bp--lg {
			@include rem(font-size, 60px);
			@include rem(line-height, 60px);
		}
	}

  
}

.sponsor-slide{
  width: 80%;
  max-width: max-content;
  height: auto;
  background-color: #002D62;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  justify-content: center;
  align-items: center;//flex-start;
  padding: 20px;
  border: #FFC60F 3px solid;

  .sponsor-images{
    width: 60%;
    max-width: 600px;
    position: relative;


    .motiv{
      position:absolute;
     // display: table-footer-group;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      visibility: hidden;
     // transform: translateY(-100%);
    }

    
    
    .motiv img{
        position: absolute;
        left: 0%;
        top: 0%;
        border: 2px solid white;
       // height: 130%;
    }

    .motiv01{
      position: relative;
    }
    
    .motiv01 img{
      position: relative;
    }
    
  }

  .sponsor-info{
    width: 40%;
    padding: 0 20px;
    display: flex;
    flex-direction: column;

    .ms-logo{

      width: 250px;
      margin-bottom: 10px;
    }

    p{
      font-size: 14px;
      //line-height: 24px;
      line-height: 20px;
      margin: 10px 0;
      text-align: left;
    }
  }
}


@media only screen and (max-width: 1080px){


  .sponsor-slide{
    width: 70%;
    height: auto;
    background-color: #002D62;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    border: #FFC60F 3px solid;
  
    .sponsor-images{
      width: 100%;
    }
  
    .sponsor-info{
      width: 100%;
      padding: 0;
      display: flex;
      flex-direction: column;
  
      .ms-logo{
  
        width: 250px;
        margin-top: 10px;
        margin-bottom: 10px;
      }
  
      p{
        font-size: 14px;
        line-height: 16px;
        margin: 10px 0;
        text-align: left;
      }
    }
  }
  }


@media only screen and (max-width: 600px){
 
  .pow .title-group{
    padding: 20px;

    p{
      font-size: 1.8rem;
      max-width: 320px;
      margin: 40px auto;
    }

    a.button--red{

      max-width: 100%;
    }
  }

  .pow .slides-inner .detail-box{
    top: 60%;
    left: 50%;
    display: flex;
    flex-direction: column;
  }

  .pow .slides-inner .feature-photo{
    position: absolute;
    width: 100%;
    top: 0;
    height: 80%;
    max-height: 600px;
    left: 50%;
    transform: translate(-50%,0%);
    z-index: -1;
  }

  .pow{
    .slide .candidate-grid{

      display: grid;
      grid-template-columns: 49% 49%;
    grid-row: auto auto;
    grid-column-gap: 2%;
    grid-row-gap: 10px;
    padding: 10px;

      .detail-box{

        display: flex;
        flex-direction: column;
        position: relative;
      // top: unset !important;
        //left: unset !important;
      // width: auto !important;
        /* width: 50%; */
        justify-content: flex-start;
        align-items: flex-start;
        
      // transform: translate(0,0) !important;
      

        &:nth-child(odd) {
          flex-direction: column;
        }

        .head-photo{
         // width: 100% !important;
        }

        .stat-box{
          margin-left: 10px;
        }

      }

      .stat-box{
        //width: 25%;

        .stat{
          font-size: 20px;
          line-height: 18px;
        }
      }

      
    }

    .controls {


      a{
        width: 30px;
        height: 30px;
      }

      a.next{
        right: 0;
      }
      a.previous{
        left: 0;
      }

       a svg {
        width: 30px;
        height: 30px;
      }
    }


  }

  .sponsor-slide{
    width: 90%;
  }



  
}


@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
