/* ===========================================================
  HERO
=========================================================== */

.hero {
	&--home {
		position: relative;
		overflow: hidden;
		margin-top: 0;
		margin-bottom: 40px;
		padding-bottom: 50px;

		@include bp--md {
			padding-bottom: 0;
		}

		@include bp--lg {
			margin-top: -15px;
		}

		.hero-slides {
			position: relative;
			z-index: 3;
			overflow: hidden;

			.hero-slide {
				position: relative;
				display: block !important;
				@extend %clearfix;

				.container {
					position: static;

					@include bp--lg {
						position: relative;
					}
				}

				&__text {
					position: absolute;
					bottom: 30px;
					left: 30px;
					z-index: 4;
					max-width: 370px;

					@include bp--sm {
						top: 75px;
						bottom: auto;
					}

					@include bp--md {
						top: 30px;
					}

					@include bp--lg {
						top: 30px;
						left: 0;

						.cssclippathpolygon & {
							top: 100px;
						}
					}
				}

				&__label {
					margin-bottom: 2px;
					color: $red;

					a {
						transition: color 0.3s ease;

						.no-touch &:hover {
							color: $black;
							transition: color 0.3s ease;
						}
					}

					@include bp--sm(max) {
						@include rem(font-size, 13px);
						@include rem(line-height, 18px);
					}
				}

				&__title {
					margin-bottom: 15px;
					@include rem(font-size, 30px);
					@include rem(line-height, 30px);

					@include bp--sm {
						@include rem(font-size, 40px);
						@include rem(line-height, 40px);
					}

					@include bp--md {
						@include rem(font-size, 48px);
						@include rem(line-height, 48px);
					}

					@include bp--lg {
						@include rem(font-size, 52px);
						@include rem(line-height, 52px);
					}

					a {
						display: block;
						color: $black;
						padding-right: 16px;
						transition: all 0.3s ease;

						.no-touch &:hover {
							border-left: 6px $red solid;
							padding-left: 10px;
							padding-right: 0;
							color: $red;
							transition: all 0.3s ease;
						}

						&:focus {
							outline: none;
						}
					}
				}

				&__link {
					margin-left: -13px;
					@include rem(line-height, 20px);

					@include bp--sm(max) {
						@include rem(font-size, 14px);
					}

					a {
						display: inline-block;
						color: $black;

						&::before,
						&::after {
							display: inline-block;
							vertical-align: middle;
							content: '';
							opacity: 0;
							width: 4px;
							height: 16px;
							background: $red;
							transition: transform 0.3s, opacity 0.2s;
						}

						&::before {
							margin-right: 10px;
							transform: translateX(20px);
						}

						&::after {
							margin-left: 10px;
							transform: translateX(-20px);
						}

						span {
							position: relative;
							display: inline-block;
							vertical-align: middle;
							@include rem(line-height, 20px);

							@include bp--sm(max) {
								@include rem(line-height, 14px);
							}
						}

						.no-touch &:hover {
							&::before,
							&::after {
								opacity: 1;
								transform: translateX(0);
							}
						}
					}
				}

				&__image {
					&--clipped {
						@extend %clearfix;
						position: relative;
						float: right;
						z-index: 3;
						width: 100%;

						.cssclippathpolygon & {
							width: 90%;
						}

						@include bp--xs {
							.cssclippathpolygon & {
								width: 80%;
							}
						}
						
						@include bp--sm {
							width: 55%;
							margin-right: -10%;

							.cssclippathpolygon & {
								width: 60%;
							}
						}

						@include bp--lg {
							width: 55%;
							margin-right: 0;

							.cssclippathpolygon & {
								width: 78%;
							}
						}

						img {
							display: block;
							width: 100%;
							height: auto;
							margin-bottom: -20px;

							.cssclippathpolygon & {
								clip-path: polygon(0 0, 100% 0%, 100% 100%, 33% 100%);
							}

							&.hero-desktop-img {
								display: none;
							}

							@include bp--sm {
								.cssclippathpolygon & {
									clip-path: polygon(0 0, 67% 0, 100% 100%, 33% 100%);
								}

								&.hero-desktop-img {
									display: block;
								}

								&.hero-mobile-img {
									display: none;
								}
							}
						}

						&::after {
							position: absolute;
							right: 0;
							bottom: 0;
							z-index: 2;
							display: block;
							content: '';
							width: 100%;
							height: 100%;
							background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
							background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
							background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
							filter: progid:dximagetransform.microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0);

							@include bp--sm {
								display: none;
							}
						}
					}

					&--backdrop {
						display: none;
						position: absolute;
						bottom: 0;
						left: 0;
						z-index: 3;
						width: 35%;
						height: 85%;
						overflow: hidden;

						.cssclippathpolygon & {
							display: block;
						}

						@include bp--xs {
							width: 50%;
						}

						@include bp--sm {
							left: auto;
							right: 0;
							bottom: 22%;
							z-index: 1;
						}

						@include bp--lg {
							display: block;
						}

						.backdrop-wrap {
							position: relative;
							width: 100%;
							height: 100%;
							background: $gray-lt;
							overflow: hidden;
							clip-path: polygon(0 0, 27% 0, 95% 100%, 0 100%);

							@include bp--xs {
								clip-path: polygon(0 0, 27% 0, 67% 100%, 0 100%);
							}

							@include bp--sm {
								background: $gray;
								clip-path: polygon(0 0, 100% 0%, 100% 100%, 35% 100%);
							}
						}

						img {
							position: absolute;
							top: 0;
							left: 0;
							right: 0;
							bottom: 0;
							display: block;
							width: 100%;
							height: 100%;
							opacity: 0;
							filter: blur(20px);

							@include bp--sm {
								opacity: 0.2;
							}
						}
					}
				}
			}
		}

		.hero-border {
			display: none;

			@include bp--md {
				display: block;
			}

			.container {
				position: relative;
			}

			.faux-border {
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				z-index: 2;
				background: $black;
				width: 98%;
				height: 20px;
			}
		}

		.hero-nav {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 4;

			&__wrap {
				width: 100%;

				@include bp--md {
					display: flex;
					width: 65%;
				}

				@include bp--lg {
					width: 50%;
				}
			}

			ul {
				display: flex;
				list-style: none;
			}

			&__pager {
				width: 100%;
				padding: 0 20px;

				@include bp--xs {
					padding: 0 40px;
				}

				@include bp--md {
					width: 75%;
					padding: 0;
				}

				ul {
					position: relative;
					width: 100%;
					justify-content: center;

					li {
						display: none;
						width: 33.33%;
						text-transform: uppercase;
						letter-spacing: 0.025em;
						text-align: center;
						@include rem(font-size, 13px);
						@include rem(line-height, 13px);
						@include font-black;

						@include bp--xs {
							display: block;
							@include rem(font-size, 15px);
							@include rem(line-height, 15px);
						}

						@include bp--sm {
							@include rem(font-size, 20px);
							@include rem(line-height, 20px);
						}

						a {
							display: inline-block;
							padding-bottom: 5px;
							color: $black;
							border-bottom: 3px transparent solid;
							transition: all 0.3s ease;
							cursor: pointer;

							@include bp--sm {
								padding-bottom: 8px;
							}

							@include bp--md {
								display: block;
								padding-bottom: 25px;
								border-bottom: 20px $black solid;
							}
						}

						&.slick-active {
							display: block;
						}

						&.slick-active a,
						.no-touch & a:hover {
							color: $red;
							transition: all 0.3s ease;
						}

						&.slick-active a {
							border-bottom: 3px $red solid;

							@include bp--md {
								border-bottom: 0;
							}
						}
					}

					.pager-line {
						display: none;
						content: '';
						position: absolute;
						bottom: 0;
						left: 0;
						width: 33.33%;
						background: $red;
						transition: transform 0.3s;
						transition-timing-function: cubic-bezier(1, 0.01, 0, 1.22);

						@include bp--md {
							display: block;
							height: 20px;
						}
					}


					li:nth-child(1).slick-active ~ .pager-line {
						transform: translate3d(0, 0, 0);
					}

					li:nth-child(2).slick-active ~ .pager-line {
						transform: translate3d(100%, 0, 0);
					}

					li:nth-child(3).slick-active ~ .pager-line {
						transform: translate3d(200%, 0, 0);
					}
				}
			}

			&__arrows {
				@include bp--md {
					width: 25%;
				}

				li {
					display: block;

					& + li {
						@include bp--md {
							margin-left: 20px;
						}
					}

					a {
						position: absolute;
						top: -5px;
						display: block;

						@include bp--xs {
							top: -2px;
						}

						@include bp--sm {
							top: 0;
						}

						@include bp--md {
							position: relative;
							top: auto;
							padding-bottom: 25px;
						}

						svg {
							width: 10px;
							height: 10px;
							fill: $black;
							transition: fill 0.3s ease;

							@include bp--xs {
								width: 15px;
								height: 15px;
							}
						}

						&.previous {
							left: 15px;

							@include bp--xs {
								left: 20px;
							}

							@include bp--md {
								left: auto;
							}

							svg {
								@include rotate(180);
							}
						}

						&.next {
							right: 15px;

							@include bp--xs {
								right: 20px;
							}

							@include bp--md {
								right: auto;
							}
						}

						.no-touch &:hover {
							svg {
								fill: $red;
								transition: fill 0.3s ease;
							}
						}
					}
				}
			}
		}
	}

	&--team {
		overflow: hidden;
		height: 185px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center right;
		background-image: url('../images/bg-team--mobile.jpg');

		@include bp--sm {
			background-image: url('../images/bg-team--desktop.jpg');
		}

		&__logo {
			padding-top: 20px;
			text-align: center;

			@include bp--sm {
				padding-top: 5px;
				padding-left: 75px;
				text-align: left;
			}

			img {
				max-width: 300px;
				max-height: 150px;
				width: auto;
				height: auto;

				@include bp--sm {
					max-width: 400px;
					max-height: 170px;
				}
			}
		}
	}

	&--player {
		position: relative;
		background: $gray-dk;
		max-height: 335px;
		overflow: hidden;

		&.team--3s-company {
			background: $company;
		}

		&.team--3-headed-monsters {
			background: $headed;
		}

		&.team--ball-hogs {
			background: $ballhogs;
		}

		&.team--ghost-ballers {
			background: $ghostballers;
		}

		&.team--killer-3s {
			background: $killer3s;
		}

		&.team--power {
			background: $power;
		}

		&.team--tri-state {
			background: $tristate;
		}

		&.team--trilogy {
			background: $trilogy;
		}

		&::before {
			display: block;
			content: '';
			z-index: 2;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: 50%;
			background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
			background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
			background: linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
			filter: progid:dximagetransform.microsoft.gradient(startColorstr='#a6000000', endColorstr='#00000000', GradientType=0);
		}

		&__name {
			position: absolute;
			bottom: 0;
			right: 0;
			z-index: 4;
			width: 100%;
			height: 42px;
			padding: 11px 20px;
			background: $black-lt;

			@include bp--sm {
				width: 67%;
				height: 50px;
				padding: 10px 20px;
			}

			@include bp--md {
				width: 63%;
				height: 64px;
				padding: 12px 40px;
			}

			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: -34px;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 0 42px 34px;
				border-color: transparent transparent #000111 transparent;
				
				@include bp--sm {
					display: block;
					border-width: 0 0 50px 34px;
				}

				@include bp--md {
					border-width: 0 0 64px 34px;
				}
			}

			h1 {
				color: $white;
				letter-spacing: 0;
				@include rem(font-size, 20px);
				@include rem(line-height, 20px);

				@include bp--sm {
					@include rem(font-size, 30px);
					@include rem(line-height, 30px);
				}

				@include bp--md {
					@include rem(font-size, 40px);
					@include rem(line-height, 40px);
				}

				.player-role {
					position: relative;
					display: inline-block;
					vertical-align: top;

					&--text {
						display: inline-block;
						margin-left: 5px;
						@include rem(font-size, 12px);
						@include rem(line-height, 20px);

						@include bp--sm {
							@include rem(font-size, 20px);
							@include rem(line-height, 30px);
						}

						@include bp--md {
							@include rem(line-height, 40px);
						}
					}

					&--icon {
						width: 20px;
						height: 20px;
						border-radius: 50%;
						background: #343434;

						@include bp--sm {
							width: 30px;
							height: 30px;
						}

						@include bp--md {
							width: 40px;
							height: 40px;
						}

						svg {
							position: absolute;
							top: 50%;
							left: 50%;
							display: block;
							margin-top: -9px;
							margin-left: -6px;
							width: 12px;
							height: 18px;
							fill: $white;

							@include bp--md {
								margin-top: -12px;
								margin-left: -8px;
								width: 16px;
								height: 24px;
							}
						}
					}
				}
			}
		}

		&__wrap {
			@include bp--sm {
				display: flex;
				justify-content: space-between;
				height: 100%;
			}
		}

		&__logo {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			height: 100%;

			@include bp--sm {
				position: relative;
				top: auto;
				left: auto;
				right: auto;
				bottom: auto;
				align-self: flex-start;
				width: 45%;
				height: auto;
			}

			img {
				position: relative;
				z-index: 1;
				display: block;
				max-width: 100%;
				max-height: 290px;
				width: auto;
				height: auto;
				filter: blur(1px);
				opacity: 0.3;
				
				@include bp--sm(max) {
					@include center-h--unk();
				}
			}
		}

		&__photo {
			position: relative;
			z-index: 3;
			padding-top: 30px;
			padding-bottom: 42px;
			@extend %clearfix;

			@include bp--sm {
				z-index: 3;
				align-self: flex-end;
				width: 34%;
				height: 100%;
				padding-bottom: 0;
			}

			img {
				display: block;
				margin: 0 auto;
				max-width: 300px;
				width: auto;
				height: auto;
				
				@include bp--sm {
					left: auto;
					right: 0;
					margin: 0;
					max-width: 100%;
					max-height: 300px;
				}
			}
		}
	}

	&--leadership,
	&--stats {
		padding: 35px 0 40px;
		background: $gray;
		text-align: center;

		@include bp--sm {
			padding: 60px 0 70px;
		}

		.content--logo {
			padding: 0;
		}

		h1 {
			text-transform: none;
			color: $red;
			@include font-bold;
			@include rem(font-size, 34px);
			@include rem(font-size, 40px);

			@include bp--sm {
				@include rem(font-size, 45px);
				@include rem(font-size, 52px);
			}
		}

		&.with-title {
			.content--logo {
				@include bp--sm {
					margin-right: 15px;
				}

				img {
					width: auto;
					height: 45px;
				}
			}

			.content--logo,
			h1 {
				display: block;

				@include bp--sm {
					display: inline-block;
				}
			}
		}
	}

	&--featured {
		position: relative;

		@include bp-custom(975px) {
			padding: 65px 0 30px;
		}

		.featured-blurred {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 50%;
			z-index: 1;
			background: $black-lt-2;
			overflow: hidden;

			img {
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				min-width: 100%;
				min-height: 100%;
				opacity: 0.6;
				filter: blur(15px);
			}
		}

		.container {
			position: relative;
			z-index: 2;

			.featured-image {
				display: block;
				width: 100%;
				height: auto;
			}
		}
	}

	&--news {
		position: relative;
		overflow: hidden;
		margin-top: 0;
		@extend %clearfix;

		body:not(.category) & {
			margin-bottom: 30px;
		}

		@include bp--md {
			padding-bottom: 0;

			body:not(.category) & {
				margin-bottom: 75px;
			}
		}

		.container {
			position: static;

			@include bp--lg {
				position: relative;
			}
		}

		&__text {
			position: relative;
			padding: 0 20px;
			z-index: 99;

			@include bp--sm {
				position: absolute;
				top: 20px;
				right: 10px;
				width: 40%;

				.cssclippathpolygon & {
					padding: 0;
					width: 50%;
				}
			}

			@include bp--md {
				top: 30px;
			}

			@include bp--lg {
				top: 50px;
				right: 0;
				width: 55%;
				padding: 0 50px;

				.cssclippathpolygon & {
					width: 40%;
					top: 50px;
					right: 14%;
				}
			}

			@include bp--xl {
				.cssclippathpolygon & {
					width: 35%;
					top: 70px;
				}
			}

			@include bp-custom(1800px) {
				.cssclippathpolygon & {
					top: 100px;
					right: 21%;
				}
			}
		}

		&__label {
			margin-bottom: 2px;
			color: $red;

			a {
				transition: color 0.3s ease;

				.no-touch &:hover {
					color: $black;
					transition: color 0.3s ease;
				}
			}

			@include bp--sm(max) {
				@include rem(font-size, 13px);
				@include rem(line-height, 18px);
			}
		}

		&__title {
			margin-bottom: 15px;
			@include rem(font-size, 30px);
			@include rem(line-height, 30px);

			@include bp--sm {
				@include rem(font-size, 34px);
				@include rem(line-height, 34px);
			}

			@include bp--md {
				@include rem(font-size, 50px);
				@include rem(line-height, 50px);
				max-width: 600px;

				.cssclippathpolygon & {
					max-width: none;
				}
			}

			@include bp--xl {
				@include rem(font-size, 60px);
				@include rem(line-height, 60px);
			}

			a {
				display: block;
				color: $black;
				transition: all 0.3s ease;
				padding-right: 10px;

				.no-touch &:hover {
					border-left: 6px $red solid;
					padding-left: 10px;
					padding-right: 0;
					color: $red;
					transition: all 0.3s ease;
				}
			}
		}

		&__link {
			margin-left: -13px;
			margin-top: 20px;
			@include rem(line-height, 20px);

			@include bp--lg {
				margin-top: 50px;
			}

			@include bp--sm(max) {
				@include rem(font-size, 14px);
			}

			a {
				display: inline-block;
				color: $black;

				&::before,
				&::after {
					display: inline-block;
					vertical-align: middle;
					content: '';
					opacity: 0;
					width: 4px;
					height: 16px;
					background: $red;
					transition: transform 0.3s, opacity 0.2s;
				}

				&::before {
					margin-right: 10px;
					transform: translateX(20px);
				}

				&::after {
					margin-left: 10px;
					transform: translateX(-20px);
				}

				span {
					position: relative;
					display: inline-block;
					vertical-align: middle;
					@include rem(line-height, 20px);

					@include bp--sm(max) {
						@include rem(line-height, 14px);
					}
				}

				.no-touch &:hover {
					&::before,
					&::after {
						opacity: 1;
						transform: translateX(0);
					}
				}
			}
		}

		&__image {
			&--clipped {
				@extend %clearfix;
				position: relative;
				z-index: 3;
				width: 100%;

				@include bp--sm {
					float: left;
					width: 60%;
					margin-right: -10%;

					.cssclippathpolygon & {
						width: 50%;
					}
				}

				@include bp--lg {
					margin-right: 0;
					width: 45%;

					.cssclippathpolygon & {
						width: 50%;
					}
				}

				img {
					display: block;
					width: 100%;
					height: auto;
					margin-bottom: -20px;

					.cssclippathpolygon & {
						clip-path: polygon(0 0, 75% 0, 100% 100%, 0% 100%);
					}

					&.hero-desktop-img {
						display: none;
					}

					@include bp--sm {
						.cssclippathpolygon & {
							clip-path: polygon(0 0, 67% 0, 100% 100%, 0% 100%);
						}

						&.hero-desktop-img {
							display: block;
						}

						&.hero-mobile-img {
							display: none;
						}
					}
				}

				&::before {
					display: none;
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					z-index: 2;
					content: '';
					width: 40%;
					height: 100%;
					background: -moz-linear-gradient(right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
					background: -webkit-linear-gradient(right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
					background: linear-gradient(to left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
					filter: progid:dximagetransform.microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1);

					@include bp--sm {
						display: block;

						.cssclippathpolygon & {
							display: none;
						}
					}
				}

				&::after {
					position: absolute;
					right: 0;
					bottom: 0;
					z-index: 2;
					display: block;
					content: '';
					width: 100%;
					height: 100%;
					background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
					background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
					background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
					filter: progid:dximagetransform.microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0);

					@include bp--sm {
						display: none;
					}
				}
			}

			&--backdrop {
				display: none;
				position: absolute;
				top: 0;
				right: 0;
				z-index: 3;
				width: 27%;
				height: 50%;
				overflow: hidden;

				.cssclippathpolygon & {
					display: block;
				}

				@include bp--sm {
					left: auto;
					right: 0;
					bottom: 22%;
					z-index: 1;
					width: 25%;
					height: 85%;
				}

				.backdrop-wrap {
					position: relative;
					width: 100%;
					height: 100%;
					background: $gray-lt;
					overflow: hidden;
					clip-path: polygon(27% 0, 100% 0, 100% 100%, 83% 100%);

					@include bp--xs {
						clip-path: polygon(27% 0, 100% 0%, 100% 100%, 75% 100%);
					}

					@include bp--sm {
						clip-path: polygon(0 0, 100% 0%, 100% 100%, 54% 100%);
					}
				}

				img {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					display: block;
					width: 100%;
					height: 100%;
					opacity: 0;
					filter: blur(10px);

					@include bp--sm {
						opacity: 0.2;
					}
				}
			}
		}
	}

	&--lander {
		position: relative;
		height: 185px;

		@include bp--sm {
			height: 400px;
		}

		&__bg {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-size: cover;
			background-position: center center;
			background-repeat: no-repeat;

			&.desktop-only {
				display: none;

				@include bp--sm {
					display: block;
				}
			}

			&.mobile-only {
				display: block;

				@include bp--sm {
					display: none;
				}
			}
		}
	}
}
