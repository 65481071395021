/* ===========================================================
  LOADING ANIMATIONS
=========================================================== */

@-webkit-keyframes sk-rotatePlane {
	0% {
		transform: perspective(120px) rotateX(0deg) rotateY(0deg);
	}
  
	50% {
		transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
	}
	
	100% {
		transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
	}
}

@keyframes sk-rotatePlane {
	0% {
		transform: perspective(120px) rotateX(0deg) rotateY(0deg);
	}

	50% {
		transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
	}

	100% {
		transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
	}
}

.loading {
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
	z-index: 999;
	visibility: hidden;
	height: 100%;
	padding: 0 20px;
	background: $white;
	transition: all 0.3s ease;

	@include bp-custom(620px) {
		padding: 0;
	}

	&.is-active {
		opacity: 1;
		visibility: visible;
	}

	&--init {
		opacity: 0;
		transition: opacity 0.3s ease;
		@include center-h--unk();

		&.is-active {
			opacity: 1;
		}

		.loading-image {
			position: relative;
			margin: 0 auto;
			max-width: 600px;

			img {
				&.loading-gif {
					width: 100%;
					height: auto;
					opacity: 0.5;
				}

				&.loading-logo {
					position: absolute;
					top: 42%;
					left: 0;
					width: auto;
					height: 16%;
				}
			}

			.loading-progress {
				position: absolute;
				top: 62%;
				left: 25%;
				right: 0;
				width: 75%;
				height: 10px;
				background: $blue;

				.cssclippathpolygon & {
					clip-path: polygon(0 0, 98% 0, 100% 100%, 2% 100%);
				}

				.loading-bar {
					width: 0;
					height: 100%;
					background: $red;
				}
			}
		}
	}

	&--simple {
		opacity: 0;
		transition: opacity 0.3s ease;
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		margin-top: -65px;
		

		&.is-active {
			opacity: 1;
		}

		.rotating-parallelogram {
			width: 80px;
			height: 50px;
			background-color: $gray;
			margin: 40px auto;
			animation: sk-rotatePlane 1.2s infinite ease-in-out;

			.cssclippathpolygon & {
				clip-path: polygon(0 0, 67% 0, 100% 100%, 33% 100%);
			}
		}
	}
}
