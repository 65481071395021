/* ===========================================================
  SEARCH
=========================================================== */

.fh-search {
	padding: 20px 25px 10px;
	max-width: 50%;

	.is-active & {
		max-width: 100%;

		@include bp--sm {
			max-width: 50%;
		}
	}

	@include bp--lg {
		display: flex;
		align-items: center;
		margin-left: 30px;
		padding: 0;
		max-width: none;
	}

	&__form {
		display: block;
		
		@include bp--lg {
			display: none;
			padding: 8px 35px 8px 25px;
			height: 100%;
			border-left: 1px $gray solid;
		}

		&.active {
			display: block;
		}

		h6 {
			display: none;
			text-transform: uppercase;
			color: $red;
			letter-spacing: 0.025em;
			@include rem(font-size, 10px);
			@include rem(font-size, 10px);

			@include bp--lg {
				display: block;
			}
		}

		form {
			position: relative;
			width: 100%;
			max-width: 100%;

			@include bp--lg {
				margin-top: -3px;
				width: 270px;
			}

			input[type='search'] {
				display: block;
				width: 100%;
				padding: 0 15px 4px 0;
				background: none;
				border: 0;
				border-bottom: 4px $gray solid;
				letter-spacing: 0.025em;
				color: $black;
				transition: border-color 0.3s ease;
				@include font-black;
				@include rem(font-size, 14px);
				@include rem(line-height, 14px);

				@include placeholder {
					color: $gray-dk;
					transition: opacity 0.3s ease;
				}

				&:focus {
					outline: none;
					border-color: $red;
					transition: border-color 0.3s ease;

					@include placeholder {
						opacity: 0.3;
						transition: opacity 0.3s ease;
					}
				}
			}

			.arrow-submit {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				width: 15px;
				height: 100%;

				svg {
					position: absolute;
					top: 5px;
					right: 0;
					margin-left: auto;
					margin-right: auto;
					display: block;
					width: auto;
					height: 10px;
					fill: $gray-md;
					transition: fill 0.3s ease;
				}

				input[type='submit'] {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					padding: 0;
					width: 15px;
					opacity: 0;
					cursor: pointer;
				}

				.no-touch &:hover {
					svg {
						fill: $red;
						transition: fill 0.3s ease;
					}
				}
			}
		}
	}

	&__toggle {
		display: none;
		height: 100%;
		color: $black;
		transition: color, 0.3s ease;
		@include rem(font-size, 18px);
		@include rem(line-height, 18px);

		@include bp--lg {
			display: block;
		}

		svg {
			width: auto;
			height: 25px;
			@include center-h--unk();

			&.fa-times {
				display: none;
			}
		}

		.no-touch &:hover {
			color: $red;
			transition: color, 0.3s ease;
		}

		&.active {
			svg {
				&.fa-times {
					display: block;
				}

				&.fa-search {
					display: none;
				}
			}
		}
	}

	&--side {
		margin-top: 40px;
		max-width: 270px;

		.sidebar & {
			display: none;
			
			@include bp--lg {
				display: block;
			}
		}

		form {
			position: relative;

			input[type='search'] {
				position: relative;
				z-index: 2;
				display: block;
				background: transparent;
				padding: 3px 50px 5px 15px;
				width: 100%;
				border: 0;
				border-radius: 0;
				@include font-bold;
				@include rem(font-size, 20px);
				@include rem(line-height, 25px);

				@include placeholder {
					color: $black;
					transition: opacity 0.3s ease;
				}

				&:focus {
					outline: none;

					@include placeholder {
						opacity: 0.3;
					}
				}
			}

			input[type='submit'] {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				z-index: 2;
				width: 40px;
				padding: 0;
				background: transparent;
				border: 0;
				border-radius: 0;
				text-transform: uppercase;
				cursor: pointer;
				transition: color 0.3s ease;
				color: $black;
				@include font-black;
				@include rem(font-size, 15px);
				@include rem(line-height, 15px);

				&:focus {
					outline: none;
				}

				.no-touch &:hover {
					background: none;
					color: $red;
					transition: color 0.3s ease;
				}
			}

			.search-border {
				position: absolute;
				top: 18px;
				left: 0;
				right: 0;
				bottom: 0;
				z-index: 1;
				width: 100%;
				max-height: 15px;
				background: $gray;
				transition: all 0.3s ease;
			}

			input[type='search']:focus + .search-border {
				top: -3px;
				max-height: 999px;
				transition: all 0.3s ease;
			}
		}
	}
}

.search-result {
	& + .search-result {
		border-top: 1px $gray solid;
		padding-top: 1.5em;
		margin-top: 1.5em;
	}

	h3 {
		text-transform: none;

		@include bp--sm(max) {
			@include rem(font-size, 20px);
			@include rem(line-height, 26px);
		}

		a {
			color: $black;
			transition: color 0.3s ease;

			.no-touch &:hover {
				color: $red;
				transition: color 0.3s ease;
			}
		}
	}

	p.read-more {
		a {
			transition: color 0.3s ease;

			.no-touch &:hover {
				color: $black;
				transition: color 0.3s ease;
			}
		}
	}
}
