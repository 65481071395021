/* ===========================================================
  TEAMS – ARCHIVE
=========================================================== */

.teams--list {
	margin: 0 !important;
	padding: 0;
	border-top: 1px $gray-lt solid;
	border-bottom: 1px $gray-lt solid;
	list-style: none;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: stretch;

	@include bp--sm {
		margin: 50px 0 0 !important;
		border-top: 0;
		border-bottom: 0;
	}

	li {
		display: block;
		width: 50%;
		margin-bottom: 0;
		text-align: right;

		@include bp--sm {
			width: 25%;
			margin-bottom: 20px;
			padding-bottom: 40px;
		}

		@include bp--lg {
			margin-bottom: 40px;
		}

		&:nth-of-type(4n),
		&:nth-of-type(4n+1) {
			a {
				background: $white;

				@include bp--sm {
					background: none;
				}
			}
		}

		a {
			position: relative;
			display: block;
			background: $gray-lt;
			padding: 30px;
			height: 100%;

			@include bp--sm {
				background: none;
				padding: 10px 20px 20px;
			}

			&::before {
				display: none;
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				opacity: 0;
				background: $gray;
				transform: translate3d(-50%, -50%, 0);
				transition: opacity 0.35s, transform 0.35s;

				.cssclippathpolygon & {
					clip-path: polygon(0 0, 67% 0, 100% 100%, 33% 100%);
				}

				@include bp--sm {
					display: block;
				}
			}
		}

		img {
			display: block;
			margin: 0 auto;
			max-width: 275px;
			max-height: 200px;
			width: auto;
			height: auto;
			@include center-h--unk();
		}

		.cta {
			display: none;
			position: absolute;
			right: 0;
			bottom: -35px;
			padding-left: 65px;
			color: $red;
			letter-spacing: 0.025em;
			text-transform: uppercase;
			opacity: 0;
			transform: translate3d(50%, 50%, 0);
			transition: opacity 0.35s, transform 0.35s;
			@include font-black;
			@include rem(font-size, 16px);
			@include rem(line-height, 16px);

			&::before {
				display: block;
				content: '';
				position: absolute;
				top: 50%;
				left: 0;
				margin-top: -1.5px;
				width: 50px;
				height: 3px;
				background: $red;
			}

			@include bp--sm {
				display: inline-block;
			}
		}

		.no-touch & a:hover {
			&::before {
				opacity: 1;
				transform: translate3d(0, 0, 0);
			}

			.cta {
				opacity: 1;
				transform: translate3d(0, 0, 0);
			}
		}
	}
}

/* ===========================================================
  TEAMS – SINGLE
=========================================================== */

.team {
	&__schedule {
		max-width: 275px;

		h6 {
			margin: 20px 0 10px;
			@include rem(font-size, 13px);
		}
	}

	&__ad {
		margin: 30px 0 0;

		@include bp--sm {
			margin: 70px 0 0;
		}
	}

	&__news-wrap {
		margin: 30px 0 0;

		@include bp--lg {
			margin: 60px 0 0;
		}
		
		> h6 {
			margin-bottom: 20px;
		}

		.border-link {
			margin: 40px 0;
		}
	}

	&__news {
		@include bp--lg {
			display: flex;
		}

		.news--featured {
			display: block;
			margin-bottom: 20px;

			@include bp--lg {
				margin-bottom: 0;
				width: 50%;
			}

			.news-item {
				margin: 0;
			}
		}

		.news--additional {
			@include bp--lg {
				width: 50%;
				padding-left: 40px;
			}
		}
	}
}


.table-container.with-frozen tr {
    position: static;
}
