/* ===========================================================
  ADS
=========================================================== */

.ad__unit {
	&--side {
		margin-top: 2em;
		max-width: 250px;

		@include bp--sm {
			margin-top: 3em;

			.recap-sidebar & {
				float: right;
			}
		}
	}

	&--content {
		margin: 30px 0 0;
		text-align: center;
	}
	
	&--bottom {
		margin: 30px 0 40px;
		text-align: center;

		@include bp--sm {
			margin: 60px 0 75px;
		}

		.container {
			padding: 0 20px;

			@include bp--lg {
				padding: 0;
			}
		}
	}

	.desktop-only {
		display: none;

		@include bp--sm {
			display: inline;
		}
	}

	.mobile-only {
		display: inline;

		@include bp--sm {
			display: none;
		}
	}
}
