/* ===========================================================
  FOOTER
=========================================================== */

.footer {
	.page-template-tpl-iframe & {
		display: none;
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 499;
		padding-top: 20px;
		background: $white;
		border-top: 1px $gray solid;

		@include bp--md {
			display: block;
		}
	}

	@include bp--sm {
		text-align: center;
	}

	.container {
		padding: 0 20px;

		@include bp--lg {
			padding: 0 100px;
		}
	}

	&__top {
		@include bp--md {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.subscribe {
			@include bp--sm {
				margin-left: 15px;
				margin-right: 15px;
			}

			form {
				margin: 30px 0;

				@include bp--sm {
					margin: 30px auto;
				}

				@include bp--md {
					margin: 0;
				}
			}
		}
	}

	&__logo {
		margin-bottom: 5px;

		img {
			width: auto;
			height: 35px;

			@include bp--sm {
				height: 30px;
			}
		}
	}

	&__menu {
		margin: 0;
		padding: 0;
		list-style: none;

		@include bp--sm {
			display: flex;
			justify-content: center;
			

		}

		@include bp--md {
			//@extend: flex-end;
			justify-content: flex-start;
    		padding: 0 40px;
			flex-wrap: wrap;
		}

		li {
			display: block;
			letter-spacing: 0.025em;
			text-transform: uppercase;
			@include font-black;
			@include rem(font-size, 17px);
			@include rem(line-height, 30px);



			@include bp--sm {
				@include rem(font-size, 14px);
				@include rem(line-height, 14px);

				margin-right: 20px;
				
			}

			& + li {
				@include bp--sm {
					//margin-left: 15px;
				//	margin-right: 20px
				}

				@include bp--lg {
					//margin-left: 25px;
				}
			}

			a {
				display: block;
				color: #a1a1a1;
				transition: color 0.3s ease;
			}

			.no-touch & a:hover,
			&.current-menu-item a {
				color: $black;
				transition: color 0.3s ease;
			}

			ul {
				display: none;
			}
		}
	}

	&__bottom {
		padding: 25px 0;

		p {
			margin: 0;
			color: #ccc;
			@include font-bold;
			@include rem(font-size, 11px);
			@include rem(line-height, 14px);

			a {
				color: #ccc;
				transition: color 0.3s ease;

				.no-touch &:hover {
					color: $gray-dk;
					transition: color 0.3s ease;
				}
			}
		}
	}

	&__link {
		margin-bottom: 30px;
		text-align: right;

		@include bp--sm {
			margin-bottom: 40px;
		}

		.arrow {
			display: inline-block;
			padding-left: 10px;

			svg {
				width: 13px;
				height: 13px;
			}
		}

		&--back {
			text-align: left;

			.arrow {
				padding-left: 0;
				padding-right: 10px;

				svg {
					@include rotate(180);
				}
			}
		}
	}
}
