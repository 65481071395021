/* ===========================================================
  POST META
=========================================================== */

.post-meta {
	margin: 0 0 1.5em;

	h5 {
		margin: 0;
		color: $red;

		a {
			transition: color 0.3s ease;

			.no-touch &:hover {
				color: $black;
				transition: color 0.3s ease;
			}
		}
	}

	h1 {
		margin: 0;
		@include rem(font-size, 50px);
		@include rem(line-height, 58px);
	}

	p {
		margin: 1em 0 0;
		@include font-black;
		@include rem(font-size, 12px);
		@include rem(line-height, 16px);

		span {
			display: block;
			color: #898989;
			@include font-bold;
		}
	}
}
