/* ===========================================================
  NEWS
=========================================================== */

.news-item {
	display: block;
	position: relative;

	img {
		transition: transform 0.3s ease;

		&.desktop-only {
			display: none;

			@include bp--xs {
				display: block;
			}
		}

		&.mobile-only {
			display: block;

			@include bp--xs {
				display: none;
			}
		}
	}

	.news-title {
		h5 {
			margin: 0;
			/* text-transform: none; */
			transition: all 0.3s ease;
			@include font-bold;
			@include rem(font-size, 18px);
			@include rem(line-height, 22px);
		}

		h6 {
			color: $red;
			@include rem(font-size, 12px);
			@include rem(line-height, 16px);
		}

		p {
			display: none;
			color: $black;
			@include rem(font-size, 14px);
			@include rem(line-height, 20px);

			@include bp--xs {
				display: block;
			}
		}
	}

	.news-more {
		display: block;
		margin: 15px 0 0;
		color: $black;
		text-transform: uppercase;
		letter-spacing: 0.025em;
		@include font-black;
		@include rem(font-size, 13px);
		@include rem(line-height, 17px);
	}

	.no-touch &:hover {
		img {
			transform: scale(1.05);
			transition: transform 0.3s ease;
		}
	}
}

.news--featured {
	@include bp--sm {
		display: flex;
		justify-content: center;
		align-items: flex-start;
	}

	.news-item {
		max-width: 500px;
		overflow: hidden;
		margin: 0 auto;

		@include bp--xs {
			max-width: 410px;
		}

		@include bp--sm {
			margin: 0;
		}

		&.featured-square {
			max-width: 300px;
		}

		&.featured-large {
			@include bp--xs {
				max-width: 500px;
			}
		}

		&::after {
			display: block;
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			background: $red;
			height: 10px;
			max-height: 0;
			transition: max-height 0.3s ease;
		}

		figcaption {
			display: block;
			position: absolute;
			bottom: 0;
			right: 0;
			background: $white;
			width: 80%;
			padding: 0 25px 25px;
			backface-visibility: hidden;
			transition: padding 0.3s ease;

			@include bp--sm {
				width: 60%;
			}
		}

		.news-title,
		.news-more {
			transform: translate3d(0, 25px, 0);
		}

		.news-title {
			transition: transform 0.35s ease;
		}

		.news-more {
			display: block;
			opacity: 0;
			backface-visibility: hidden;
			transition: opacity 0.2s ease, transform 0.35s ease;
		}

		.no-touch &:hover {
			&::after {
				max-height: 10px;
				transition: max-height 0.3s ease;
			}

			figcaption {
				padding: 25px;
				transition: padding 0.3s ease;
			}

			.news-title {
				h5 {
					color: $red;
					text-decoration: underline;
					transition: all 0.3s ease;
				}
			}

			.news-title,
			.news-more {
				transform: translate3d(0, 0, 0);
			}

			.news-more {
				opacity: 1;
				transition-delay: 0.05s;
				transition-duration: 0.35s;
			}
		}
	}
}

.news--additional {
	.news-item {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		height: 32.5%;

		& + .news-item {
			margin-top: 3px;
		}

		img {
			max-width: 160px;
			max-height: 100%;
			width: auto;
			height: auto;
		}

		figcaption {
			width: 50%;
			padding-left: 20px;
		}

		.news-image {
			width: 50%;
			max-width: 160px;
			overflow: hidden;

			img {
				display: block;
			}
		}

		.news-title {
			h5 {
				@include font-bold;
				@include rem(font-size, 14px);
				@include rem(line-height, 18px);
			}
		}

		.no-touch &:hover {
			.news-title {
				h5 {
					color: $red;
					text-decoration: underline;
				}
			}
		}
	}
}

.news--list {
	.news-item {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;

		& + .news-item {
			margin-top: 6px;
		}

		img {
			max-width: 316px;
			max-height: 100%;
			width: 100%;
			height: auto;
		}

		figcaption {
			width: 55%;
			padding-left: 20px;

			@include bp--xs {
				width: 50%;
				padding-left: 40px;
			}
		}

		.news-image {
			width: 45%;
			max-width: 316px;
			overflow: hidden;

			@include bp--xs {
				width: 50%;
			}

			img {
				display: block;
			}
		}

		.news-title {
			h6 {
				margin: 0 0 0.25em;
			}

			h5 {
				margin: 0 0 0.25em;
				@include font-bold;
				@include rem(font-size, 14px);
				@include rem(line-height, 18px);

				@include bp--xs {
					@include rem(font-size, 19px);
					@include rem(line-height, 23px);
				}
			}
		}

		.no-touch &:hover {
			.news-title {
				h5 {
					color: $red;
					text-decoration: underline;
				}
			}
		}
	}

	> .pagination {
		margin-top: 2em;
	}
}

.news--related {
	margin: 0 0 2em;

	h6 {
		margin: 0 0 1em;
	}

	&__wrap {
		@include bp--lg {
			display: flex;
		}
	}

	.news--featured {
		justify-content: flex-start;

		.news-item {
			margin: 0;
		}
	}

	.news--additional {
		margin-top: 20px;

		@include bp--sm {
			margin-top: 40px;
			max-width: 450px;
		}

		@include bp--lg {
			margin-top: 0;
			padding-left: 40px;
		}

		figcaption {
			width: 55%;
		}

		.news-image {
			width: 45%;
		}
	}
}

.news--secondary {
	margin-bottom: 30px;

	@include bp--sm {
		margin-bottom: 70px;
	}

	.container {
		@include bp--md {
			display: flex;
		}
	}

	&__side {
		@include bp--md {
			width: 28%;
			padding-right: 20px;
		}

		.twitter-widget {
			width: 100%;
		}
	}

	&__main {
		margin-top: 30px;

		@include bp--md {
			width: 72%;
			margin-top: 0;
			padding-left: 20px;
		}
	}
}
