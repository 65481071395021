/* ===========================================================
  HEADER
=========================================================== */

.header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 599;
	background: $white;
	border-bottom: 1px $gray solid;
	//height: $header-height;

	/*
	&.with-stats {
		top: $stats-height;
	}
*/
/*
	&::before {
		display: block;
		content: '';
		opacity: 1;
		position: absolute;
		top: 0;
		left: 0;
		right: 50%;
		bottom: 0;
		background: $gray-lt;
		transition: all 0.3s ease;
	}
	*/

	.container {
		position: relative;
		z-index: 599;
		background: $white;
		padding-right: 15px;

		@include bp--md {
			padding-right: 30px;
		}

		@include bp--lg {
			padding-right: 0;
		}
	}

	.container--large{
		max-width: 100%;
	}

	&__wrap {
		display: flex;
		flex-wrap: nowrap;
		align-items: stretch;
		justify-content: space-between;
		height: $header-height - 1px;

		@include bp--lg {
			padding-right: 20px;
		}

		@include bp--xl {
			padding-right: 0;
		}
	}

	&__logo {
		position: relative;
		z-index: 699;
		padding: 14px 40px 14px 15px;
		background: $gray-lt;
		transition: all 0.3s ease;

		@include bp--xs {
			padding: 12px 65px 14px 20px;
		}

		&::after {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			opacity: 1;
			display: block;
			content: '';
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 35px 65px 0;
			border-color: transparent #fff transparent transparent;
			transition: all 0.3s ease;
		}

		img {
			position: relative;
			display: block;
			width: 90px;
			transition: all 0.3s ease;

			@include bp--xs {
				width: 110px;
			}
		}
	}

	@include bp--lg {
		&:not(.fixed):not(.with-stats, .with-video) {
			.home & {
				border-color: $white;

				&::before {
					opacity: 0;
				}

				.header__logo {
					padding: 0 40px;
					background: none;

					&::after {
						opacity: 0;
						transition: all 0.3s ease;
					}

					img {
						width: 180px;
						bottom: -15px;
						transition: all 0.3s ease;
					}
				}
			}
		}
	}

	&__menus {
		opacity: 0;
		visibility: hidden;
		position: fixed;
		top: 0; //$header-height;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		padding: 30px;
		background: $gray-lt;
		transition: all 0.3s ease;
		overflow-y: scroll;
		-ms-overflow-style: none;
		overflow: -moz-scrollbars-none;
		transition-property: opacity, visibility;
		transition-duration: 0.3s, 0s;
		transition-delay: 0s, 0.4s;

		@include bp--xs {
			padding: 30px;
		}
/*
		.with-stats & {
			top: $full-header-height;
		}
*/
		&::-webkit-scrollbar {
			display: none;
		}

		@include bp--lg {
			opacity: 1;
			visibility: visible;
			position: relative;
			top: 0 !important;//auto;
			left: auto;
			right: auto;
			bottom: auto;
			z-index: 599;
			display: flex;
			flex-wrap: nowrap;
			align-items: stretch;
			align-content: flex-end;
			overflow: visible;
			width: auto;
			padding: 0;
			background: $white;
/*
			.with-stats & {
				top: auto;
			}
			*/
		}

		@include bp--xl{
			padding: 30px;
		}

		&.is-active {
			opacity: 1;
			visibility: visible;
			z-index: 599;
			transition-delay: 0s, 0s;
		}
	}

	&__collapsed {
		display: flex;
		align-items: center;

		@include bp--lg {
			display: none;
		}

		h1,
		h2 {
			margin-right: 20px;
			text-transform: uppercase;
			color: $black;
			letter-spacing: 0.075em;
			text-align: right;
			@include font-black;
			@include rem(font-size, 12px);
			@include rem(line-height, 12px);
		}
	}

	&__categories {
		opacity: 0;
		visibility: hidden;
		position: fixed;
		top: $header-height;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		padding: 30px;
		background: $gray-lt;
		transition: all 0.3s ease;
		overflow-y: scroll;
		-ms-overflow-style: none;
		overflow: -moz-scrollbars-none;
		transition-property: opacity, visibility;
		transition-duration: 0.3s, 0s;
		transition-delay: 0s, 0.4s;
/*
		.with-stats & {
			top: $full-header-height;
		}
*/
		&::-webkit-scrollbar {
			display: none;
		}

		@include bp--lg {
			display: none;
		}

		&.is-active {
			opacity: 1;
			visibility: visible;
			z-index: 599;
			transition-delay: 0s, 0s;
		}
	}
}

.hamburger {
	display: block;
	overflow: visible;
	margin: 0;
	width: 24px;
	height: 20px;
	cursor: pointer;
	transition-timing-function: linear;
	transition-duration: 0.15s;
	transition-property: opacity, filter;
	text-transform: none;
	color: inherit;
	border: 0;
	background-color: transparent;

	.hamburger-box {
		position: relative;
		display: inline-block;
		width: 24px;
		height: 20px;

		.hamburger-inner,
		.hamburger-inner::before,
		.hamburger-inner::after {
			display: block;
			content: '';
			position: absolute;
			width: 24px;
			height: 4px;
			transition-timing-function: ease;
			transition-duration: 0.15s;
			transition-property: transform;
			background-color: $red;
		}

		.hamburger-inner {
			background-color: $red;
			top: auto;
			bottom: 0;
			transition-delay: 0.13s;
			transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
			transition-duration: 0.13s;

			&::before {
				top: -8px;
				transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s, transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
			}

			&::after {
				top: -16px;
				bottom: -8px;
				transition: top 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s, opacity 0.1s linear;
			}
		}
	}

	&.is-active {
		.hamburger-inner {
			transition-delay: 0.22s;
			transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
			transform: translate3d(0, -10px, 0) rotate(-45deg);

			&::before {
				top: 0;
				transition: top 0.1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.16s, transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.25s;
				transform: rotate(-90deg);
			}

			&::after {
				top: 0;
				transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s linear 0.22s;
				opacity: 0;
			}
		}
	}
}

.catnav__toggle {
	cursor: pointer;
	transition: all 0.3s ease;

	span {
		display: inline-block;
		padding-right: 5px;

		svg {
			width: 9px;
			height: 9px;
			transition: all 0.3s ease;
			@include rotate(90);
		}
	}

	.no-touch &:hover {
		color: $red;
		
		span {
			svg {
				fill: $red;
				transition: all 0.3s ease;
			}
		}
	}

	&.is-active {
		span {
			svg {
				@include rotate(-90);
			}
		}
	}
}
