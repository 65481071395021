/* ===========================================================
  PLAYERS
=========================================================== */

.featured--players {
	position: relative;
	margin-top: 40px;
	margin-bottom: 40px;

	@include bp--lg {
		margin-top: 50px;
		margin-bottom: 100px;
	}

	&::before {
		display: block;
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		left: 50%;
		bottom: 0;
		z-index: 2;
		background: $gray-lt;
	}

	.container {
		position: relative;

		&::before {
			display: block;
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 50%;
			bottom: 0;
			z-index: 2;
			background: $gray-lt;

			@include bp--lg {
				clip-path: polygon(0 0, 100% 0%, 100% 100%, 33% 100%);
			}
		}
	}

	.player-slides {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		position: relative;
		z-index: 3;
		margin-top: 120px;

		@include bp--sm {
			flex-wrap: nowrap;
			margin-top: 0;
		}

		@include bp--lg {
			align-items: flex-start;
		}

		&__left {
			width: 100%;
			margin-top: -100px;
			padding-left: 20px;
			padding-right: 20px;

			a:focus {
				outline: none;
			}

			img {
				margin: 0 auto;
			}
			
			@include bp--sm {
				width: 55%;
				margin-top: 0;
				padding-right: 30px;

				img {
					margin: 0;
				}
			}

			@include bp--md {
				width: 65%;
			}

			@include bp--lg {
				margin-top: -20px;
				margin-bottom: -50px;
				padding-left: 0;
				padding-right: 0;
			}
		}

		&__right {
			width: 100%;
			padding-top: 30px;
			padding-left: 20px;
			padding-right: 20px;
			padding-bottom: 30px;

			@include bp--sm {
				width: 45%;
				padding-left: 0;
			}

			@include bp--md {
				width: 35%;
			}

			@include bp--lg {
				padding-top: 65px;
				padding-right: 0;
				padding-bottom: 55px;
			}

			.person-bio {
				padding-left: 15px;
				padding-right: 15px;

				@include bp--sm {
					padding-right: 0;
				}

				a:focus {
					outline: none;
				}
			}
		}

		&__title {
			margin-bottom: 20px;
			padding-left: 15px;
			padding-right: 15px;
			@include font-black;
			@include rem(font-size, 16px);
			@include rem(line-height, 20px);

			@include bp--sm {
				margin-bottom: 30px;
				padding-right: 0;
			}
		}

		&__nav {
			display: flex;
			align-items: center;
			justify-content: space-between;
			border-bottom: 10px $black solid;
			margin-top: 10px;
			margin-left: 15px;
			margin-right: 15px;
			padding: 0 0 8px;

			@include bp--sm {
				margin-top: 60px;
				margin-right: 0;
			}

			h5 {
				a {
					color: $black;
					transition: color 0.3s ease;

					.no-touch &:hover {
						color: $red;
						transition: color 0.3s ease;
					}
				}
			}

			.player-slides__arrows {
				display: flex;
				margin: 0;
				padding: 0;
				list-style: none;

				li {
					height: 15px;

					& + li {
						margin-left: 20px;
					}

					a {
						display: block;
						height: 15px;

						svg {
							width: 15px;
							height: 15px;
							fill: $black;
							transition: fill 0.3s ease;
						}

						&.previous {
							svg {
								@include rotate(180);
							}
						}

						.no-touch &:hover {
							svg {
								fill: $red;
								transition: fill 0.3s ease;
							}
						}
					}
				}
			}
		}
	}
}

.player-content {
	@include bp--sm {
		display: none;

		&.is-active {
			display: block;
		}
	}

	&__bio {
		margin: 0 0 1.5em;

		@include bp--sm {
			margin: 0 0 3em;
		}

		@include bp--lg {
			margin: 0 0 6em;
		}
	}

	&__link {
		display: block;
		margin: 0 0 1.5em;

		@include bp--sm {
			margin: 0 0 4em;
		}
	}

	&__stats {
		margin: 0 0 1.5em;

		@include bp--sm {
			margin: 0 0 3em;
		}

		@include bp--lg {
			margin: 0 0 6em;
		}

		.stats-section {
			.stats-content {
				@include bp--sm(max) {
					display: none;

					&.is-active {
						display: block;
					}
				}
			}

			h5 {
				position: relative;
				text-transform: none;
				transition: background 0.3s ease;

				.arrow {
					display: none;
				}

				@include bp--sm(max) {
					margin: 0;
					padding: 15px 50px 15px 15px;
					background: $white;
					border: 1px #c1c1c1 solid;
					border-bottom: 0;
					cursor: pointer;
					text-transform: uppercase;
					@include font-regular;
					@include rem(font-size, 16px);
					@include rem(line-height, 16px);

					.arrow {
						display: block;
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						width: 48px;
						height: 48px;
						text-align: center;
						transition: all 0.3s ease;
						@include rotate(90);
						
						svg {
							fill: #c1c1c1;
							width: 15px;
							height: 15px;
							transition: all 0.3s ease;
							@include center-h--unk();
						}
					}

					&.is-active {
						background: #dbdbdb;
						border-bottom: 1px #c1c1c1 solid;

						.arrow {
							transition: all 0.3s ease;
							@include rotate(-90);

							svg {
								fill: #9b9797;
								transition: all 0.3s ease;
							}
						}
					}
				}
			}

			.table-container {
				@include bp--sm(max) {
					margin: 0;
				}
			}

			table {
				@include bp--sm(max) {
					border-top: 0;
				}
			}

			.content-container {
				padding: 15px 15px 5px;
				background: $white;

				@include bp--sm {
					border-top: 7px $red solid;
					margin: 0 0 1.5em;
				}

				@include bp--md {
					margin: 0 0 2.5em;
				}
			}

			&:last-child {
				@include bp--sm(max) {
					h5 {
						border-bottom: 1px #c1c1c1 solid;
					}
				}
			}
		}
	}
}
