 /**
 * = PRINT styles
 *
 * - Imported in global.css (http://www.phpied.com/delay-loading-your-print-css/)
 * - Taken straight from: https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L197
 *
 * @TODO:
 * - Add to this?
 *
 * ========================================================================= */

/* stylelint-disable declaration-no-important  */

.only-print{
	display: none !important;
}

.only-printX{
	opacity:0;
    position: absolute;
}
.printable-footer tr th{
    opacity: 0;
    border: 0;
}

a.print-page{
    position: absolute;
    right: 0;
    /* bottom: 22px; */
    font-size: 1.2rem;

    color: red;

        &:hover{
            color: black;
        }
    
}


@media print {
	*,
	*:before,
	*:after,
	*:first-letter,
	p:first-line,
	div:first-line,
	blockquote:first-line,
	li:first-line {
		background: transparent !important;
		color: #000 !important; /* Black prints faster:
								   http://www.sanbeiji.com/archives/953 */
		box-shadow: none !important;
		text-shadow: none !important;
	}
/*
	a,
	a:visited { text-decoration: underline; }

	a[href]:after { content: " (" attr(href) ")"; }

	abbr[title]:after { content: " (" attr(title) ")"; }
*/
	/*
	 * Don't show links that are fragment identifiers,
	 * or use the `javascript:` pseudo protocol
	 */
/*
	a[href^="#"]:after,
	a[href^="javascript:"]:after { content: ""; }
*/
	pre { white-space: pre-wrap !important; }

	pre,
	blockquote {
		border: 1px solid #999;
		page-break-inside: avoid;
	}

	/*
	 * Printing Tables:
	 * http://css-discuss.incutio.com/wiki/Printing_Tables
	 */
/*
	thead { display: table-header-group; }

	tr,
	img { page-break-inside: avoid; }
*/
	p,
	h2,
	h3 {
		orphans: 3;
		widows: 3;
	}

	h2,
	h3 { page-break-after: avoid; }

	/**
	 * HIDE certain elements completely
	 */
	.site-navigation {
		display: none;
	}

    body,
    body.with-stats {
        padding-top: 60px;
    }

    .header,
    .hero,
    .stats,
    .stats-nav,
    .back-link,
    .print-page,
    .footer,
    .no-print,
    th:before,
    th:after{
        display: none !important;

    }

    .only-print{
        display: block;
    }

    .print-hide{
        opacity: 0;
    }

    .only-printX{
        opacity:1;
        position: relative;
    }

    h3, .stats-tab h3.no-border{
        margin: 4px 0!important;
    }

    .print-header-block{
        display: block;

        .print-header{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .print-logo{
                width: 120px;
            }
            .print-title h3{
                text-align: right;
                font-size: 20px;
                line-height: 20px;
                margin: 0;
            }
        }
        
        .print-details{
            margin: 20px 0 80px;

            p{
                margin: 3px 0;
                line-height: 16px;
            }
        }
    }

    th,
    th[data-sort=float], th[data-sort=int], th[data-sort=lastname]{
   
        padding: 0 0 10px 0!important;
    }


    table.summary-box-score tr td{
        padding: 0 0 10px 0!important;
    }

    table tr th{
        border:0;
        padding: 0 0 15px 0!important;

    }

    table tr td{
        border:0;
        padding: 0 !important;
    }

    table.small-text{
        line-height: 0.6cm;
    }

    
    .stats-scores__game>.table-container-wrap .table-container,
    .table-container.with-frozen.one-frozen{
        margin:0 0 35px 0;
        transform: translateY(-35px);
    }

    .one-frozen td.frozen:first-of-type, .one-frozen th.frozen:first-of-type{
        width: 250px;
    }

    th[data-sort=float].frozen, th[data-sort=int].frozen, th[data-sort=lastname].frozen{
        position: relative;
    }

    .printable-footer tr th{
        opacity: 1;
    }

    tfoot{
        border-top: lightgrey 1px solid;
    }

    .stats-scores__game table.summary-box-score{
        border-bottom: lightgrey 1px solid;
    }

    .stats-scores__game table.summary-box-score tr.lost{
        opacity: 1;
    }



    .team-logo-left .team, .team-logo-left .team-name{
        padding-left: 0;
    }

    

    // pull summary box to left
    .stats-scores__game.is-expanded>.table-container-wrap{
        position: absolute;
        width: 300px;
    }

    .stats-tab.is-active {
        display: flex !important;
        flex-direction: column-reverse;
    }

    .stats-scores__game{
        //display: none;
    }

   // body > *:not(.full-box-score) { display: none; }

    body:not(.home) {
       // display: none;
    }


    

	/**
	 * Or go Nuclear and use this:
	 * from: https://medium.com/@matuzo/i-totally-forgot-about-print-style-sheets-f1e6604cfd6#.9de6r4yhr
	 *
	 *   body > *:not(main) { display: none; }
	 *
	 */

}
@page {
    margin: 0.5cm;
  }
/* stylelint-enable */