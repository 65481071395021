/* ===========================================================
  BUTTON
=========================================================== */

a.button {
	display: inline-block;
	padding: 12px 20px 10px;
	background: $gray-dk;
	text-transform: uppercase;
	text-align: center;
	text-decoration: none;
	color: $white;
	transition: background 0.3s ease;
	@include font-bold;
	@include rem(font-size, 20px);
	@include rem(line-height, 20px);

	.no-touch &:hover {
		background: $black;
		color: $white;
		transition: background 0.3s ease;
	}

	&--red {
		background: $red;
	}

	&--blue {
		background: $blue;
	}

	&--gold {
		background: $gold;
	}
}
