/* ===========================================================
  TICKETS SOON FORM
=========================================================== */
.page-template-tpl-tickets-soon-form,
.post-type-archive-fh-game{

	h1, h2, h3, h4, h5, p {
		color: $white;

	}

	h1{
		font-size: 65px;
    	line-height: 55px;
    	margin-bottom: 10px;
    	letter-spacing: -.5px;
    	position: relative;

    	@include bp--sm {
			//padding: 40px;
		}

		@include bp--lg {

			font-size: 125px;
    		line-height: 100px;
    		margin-bottom: 10px;
    		position: relative;

		}

		&::after{
			    content: "*";
			    position: absolute;
			    bottom: 12px;
			    right: inherit;
			    font-size: 34px;


			    @include bp--lg {
			    	bottom: 40px;
			    	right: -60px;
			    }
		}
	}

	h4, h5{
		text-transform: inherit;
		z-index: 1;
    	position: relative;
	}

	.form-pad{
		//padding: 20px;
		padding: 50px 20px;

		@include bp--sm {
			padding: 40px;
		}

		@include bp--lg {
			//padding: 45px 100px 150px;
			padding: 10% 100px !important;
		}
	}

	.coming-soon{

		z-index: 1;
		padding: 40px 20px 20px;

		@include bp--sm {
			//padding: 45px 100px;
			padding: 10% 100px !important;
		}

		.logo-soon{

		}
	}

	.contact__form{
		position: relative;
	    padding: 20px;
	    overflow: hidden;

		@include bp--sm {
			//padding: 45px 100px;
			padding: 10% 100px !important;
		}
	}




	.contact__form::before{

		@include bp--sm {
			content:"";
			background-color: rgba(0,0,0,.3);
			position: absolute;
			left: -30px;
			top:0;
			height: 100%;
			width: 100%;
		}

		@include bp--md {
			width: 120%;
		}
	}

	.submit-wrapper {
			z-index:1;
		}

	.contact-soon {

		&__pic {
			background-image: url('../images/bkgd-s3-hoop-mobile.png');

			background-size: cover;
			background-position: 50% 50%;
			background-repeat: no-repeat;
			
			//height: 200px;

			@include bp--xs {
				//height: 300px;
				
				background-image: url('../images/bkgd-s3-hoop.png');
			}

			@include bp--md {
				background-position: 100% 0;
				height: auto;
			}
		}

		&__pic::before{
			content: "";
		    background-color: rgba(0,0,0,.3);
		    position: absolute;
		    left: 0;
		    top: 0;
		    height: 100%;
		    width: 100%;

		    @include bp--xs {
		    	background-color: transparent;
		    }
		}
	}

	.cp-message,
	.wpcf7-recaptcha{
		display: none;
	}
/*
	.cp-email::after{
		content:"Your privacy is important to us. Please review our Privacy Policy here.";
		color: white;
		margin: 10px 0;
	}
*/

	.contact__form h5{
		margin-bottom:30px;
	}

.contact__form{
	.subscribe__form form{
		width:400px;
	}




	.subscribe__form form input[type=email]{
		    border-bottom: 4px solid $white;
		    color: $white;
		    font-size: 2rem;
	}




	.subscribe__form form .arrow-submit{

		position: relative;
		margin-top: 30px;
		height: inherit;
	}

	.subscribe__form form .arrow-submit input[type=submit]{

		position: relative;
		opacity:1;
		width: auto;
		height: auto;
		padding: 12px 45px;
		color: $black;
		background: $white;
		text-transform: uppercase;
		text-align: center;
		transition: background 0.3s ease;
		cursor: pointer;
		@include font-black;

		.no-touch &:hover,
		&:focus {
			outline: none;
			background: $red;
			transition: background 0.3s ease;
		}

	}

	#mce-EMAIL{

		@include placeholder {
			color: $white;
			//transition: opacity 0.3s ease;
		}

	}

	#mc_embed_signup input.mce_inline_error{
		border-color: $red !important;
	}

	#mc_embed_signup #mce-success-response, 
	#mc_embed_signup .response{
	
		color: #fff !important;
    	line-height: 14px !important;
	}


}

	input[type='text'],
	input[type='email'],
	input[type='url'],
	input[type='tel'],
	input[type='password'],
	input[type='search'],
	input[type='date'],
	input[type='datetime-local'],
	input[type='month'],
	input[type='week'],
	input[type='number'] {
		padding: 5px 8px;
		border: 0;
		border-bottom: 5px $white solid;
		background: rgba(255,255,255,0);
		border-radius: 0;
		transition: all 0.3s ease;
		color: $white;
		@include font-bold;
		@include rem(font-size, 22px);
		@include rem(line-height, 25px);

		@include placeholder {
			color: $white;
			//transition: opacity 0.3s ease;
		}

		&:focus {
			outline: none;
			border-color: $red;

			@include placeholder {
				//opacity: 0.3;
			}
		}

		.form-field-wrapper & {
			width: 100%;

			&:focus {
				@include placeholder {
					opacity: 0;
				}

				& + label {
					opacity: 1;
					visibility: visible;
					transform: translate3d(0, 0, 0);
					transition: visiblity 0.1s ease, opacity 0.2s ease, transform 0.35s ease;
				}
			}
		}
	}



	input[type='submit'] {
		padding: 12px 45px;
		border: 0;
		border-radius: 0;
		color: $black;
		background: $white;
		text-transform: uppercase;
		text-align: center;
		transition: background 0.3s ease;
		cursor: pointer;
		@include font-black;
		@include rem(font-size, 20px);
		@include rem(line-height, 20px);

		.no-touch &:hover,
		&:focus {
			outline: none;
			background: $red;
			transition: background 0.3s ease;
		}
	}

	label {
		display: block;
		@include font-bold;
		color: $red
	}

	.form-field-wrapper {
		position: relative;
		margin-bottom: 1.5em;
		padding-top: 15px;

		&.col-sm-6 {
			@include bp--sm {
				&:nth-of-type(odd) {
					padding-right: 10px;
				}

				&:nth-of-type(even) {
					padding-left: 10px;
				}
			}
		}

		label {
			position: absolute;
			top: 0;
			left: 13px;
			letter-spacing: 0.075em;
			text-transform: uppercase;
			opacity: 0;
			visibility: hidden;
			transform: translate3d(0, 25px, 0);
			backface-visibility: hidden;
			transition: opacity 0.2s ease, transform 0.35s ease;
			@include font-black;
			@include rem(font-size, 11px);
			@include rem(line-height, 11px);
		}

		&.input--filled {
			label {
				opacity: 1;
				visibility: visible;
				transform: translate3d(0, 0, 0);
				transition: visibility 0.1s ease, opacity 0.2s ease, transform 0.35s ease;
			}
		}
	}

	/* ===========================================================
	  WPCF7
	=========================================================== */

	.wpcf7-response-output {
		margin: 2em 0 0 !important;
		padding: 0 !important;
		border: 0 !important;
		font-weight: 700 !important;

		&.wpcf7-validation-errors {
			color: $red !important;
		}
	}

	.wpcf7-not-valid-tip {
		padding: 10px 0 0;
		color: $red !important;
		font-weight: 700 !important;
	}

	div.wpcf7 .ajax-loader {
		display: block;
		margin: 10px 0 0;
	}

	.wpcf7-form-control-wrap {
		position: static !important;
	}


}

.privacy{
		font-size: 1.3rem;
		margin: 4px 0;
		font-weight: bold;

		a{
			color: white;
			text-decoration: underline;

			&:hover{
				color:$red;
			}
		}
	}

.nowrap{
	white-space: nowrap;
}



/*
.contact {
	margin: 0 0 1em;

	@include bp--md {
		margin: 0 0 2em;
	}

	&__pic {
		background-size: cover;
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-image: url('../images/bg--contact.jpg');
		height: 200px;

		@include bp--xs {
			height: 300px;
		}

		@include bp--md {
			background-position: 100% 0;
			height: auto;
		}
	}

	&__form {
		padding: 20px;

		@include bp--sm {
			padding: 40px;
		}

		@include bp--lg {
			padding: 45px 100px 150px;
		}

		h2 {
			text-transform: none;
			letter-spacing: none;
			@include rem(font-size, 35px);
			@include rem(line-height, 42px);

			@include bp--sm {
				@include rem(font-size, 50px);
				@include rem(line-height, 60px);
			}

			@include bp--lg {
				@include rem(font-size, 70px);
				@include rem(line-height, 80px);
			}
		}

		textarea {
			@include bp--sm {
				margin-top: 1em;
			}
		}

		.submit-wrapper {
			margin-top: 1em;
		}
	}
}
*/
