/* ===========================================================
  PAGINATION
=========================================================== */

.pagination {
	display: flex;
	justify-content: center;
	align-items: center;
	
	&__links {
		@include rem(font-size, 16px);
		@include rem(line-height, 16px);

		@include bp--sm {
			@include rem(font-size, 20px);
			@include rem(line-height, 20px);
		}

		&--previous {
			padding-right: 10px;

			@include bp--sm {
				padding-right: 20px;
			}

			.nav-links {
				.next {
					display: none;
				}
			}
		}

		&--next {
			padding-left: 10px;

			@include bp--sm {
				padding-left: 20px;
			}

			.nav-links {
				.prev {
					display: none;
				}
			}
		}

		a {
			display: inline-block;
			color: $black;
			transition: color 0.3s ease;
			@include font-black;

			.no-touch &:hover {
				color: $red;
				transition: color 0.3s ease;
			}
		}

		.screen-reader-text,
		.nav-links .page-numbers:not(.prev):not(.next) {
			display: none;
		}
	}

	.wp-pagenavi {
		margin-left: -20px;
		padding-left: 10px;
		padding-right: 10px;

		@include bp--sm {
			margin-left: -30px;
			padding-left: 20px;
			padding-right: 20px;
		}

		.pages,
		.first,
		.last {
			display: none;
		}

		a,
		span {
			display: inline-block;
			color: $black;
			transition: color 0.3s ease;
			@include font-bold;
			@include rem(font-size, 14px);
			@include rem(line-height, 20px);

			& + a,
			& + span {
				margin-left: 20px;

				@include bp--sm {
					margin-left: 30px;
				}
			}
		}

		.current {
			color: $red;
			border-bottom: 3px $red solid;
		}

		.previouspostslink,
		.nextpostslink {
			display: none;
		}

		.no-touch & a:hover {
			color: $red;
			transition: color 0.3s ease;
		}
	}
}
