/* ===========================================================
  NAV
=========================================================== */

.nav {
	ul {
		list-style: none;
		

		li,
		li a {
			display: block;
		}

		.subnav__toggle {
			padding: 0 10px;
			cursor: pointer;
			transition: all 0.3s ease;

			@include bp--lg {
				display: none;
			}

			svg {
				width: 15px;
				height: 15px;
				transition: all 0.3s ease;
				@include rotate(90);
			}

			&.is-active {
				svg {
					transition: all 0.3s ease;
					@include rotate(270);
				}
			}

			.no-touch &:hover {
				svg {
					fill: $red;
					transition: all 0.3s ease;
				}
			}

			&-secondary {
				svg {
					width: 10px;
					height: 10px;
					fill: #a1a1a1;
				}
			}
		}
	}

	&--main {
		display: none;

		&.active {
			display: block;

			@include bp--lg {
				display: flex;
				align-items: center;
			}
		}

		ul {
			position: relative;
			display: block;
			padding: 0 15px 15px;
			border-bottom: 2px #e6e6e6 solid;
			//height: 100%;
			align-items: center;
			

			@include bp--xs {
				padding: 0 25px 25px;
			}

			@include bp--lg {
				display: flex;
				padding: 0;
				border-bottom: 0;
				
			}

			li {
				position: relative;
				letter-spacing: 0.025em;
				@include font-bold;
				@include rem(font-size, 26px);
				@include rem(line-height, 30px);

				@include bp--lg {
					text-transform: uppercase;
					@include font-black;
					@include rem(font-size, 14px);
					@include rem(line-height, 14px);
				}

				& + li {
					@include bp--lg {
						margin-left: 30px;
					}

					@include bp--xl {
						margin-left: 50px;
					}
				}

				a {
					display: inline-block;
					color: $black;
					transition: color 0.3s ease;

					.no-touch &:hover {
						color: $red;
						transition: color 0.3s ease;
					}

					@include bp--lg {
						display: block;
						padding: 27px 0 24px;
					}
				}

				&.current-menu-item,
				&.current-menu-parent {
					> a {
						color: $red;
					}
				}

				ul {
					display: none;
					margin: 10px 0;
					padding: 0 15px;
					border-bottom: 0;
					border-left: 4px $black solid;

					&.is-active {
						display: block;
					}

					@include bp--lg {
						display: block;
						opacity: 0;
						visibility: hidden;
						position: absolute;
						top: $header-height;
						left: -15px;
						z-index: 699;
						width: 250px;
						margin: 0;
						padding: 0;
						background: $white;
						border: 0;
						transition-property: opacity, visibility;
						transition-duration: 0.3s, 0s;
						transition-delay: 0s, 0.4s;
					}

					li {
						display: block;
						@include rem(font-size, 18px);
						@include rem(line-height, 26px);

						@include bp--lg {
							@include rem(font-size, 14px);
							@include rem(line-height, 14px);
						}

						& + li {
							margin: 0 !important;
						}

						a {
							display: inline-block;

							@include bp--lg {
								display: block;
								padding: 15px 20px;
							}
						}
					}

					ul {
						display: none;
					}
				}

				.no-touch &:hover > ul,
				&:active > ul {
					opacity: 1;
					visibility: visible;
					z-index: 799;
					transition-delay: 0s, 0s;
				}

				&.icon-menu {
					ul {

						top: 0 !important;
						
						
						
						@include bp--lg {
							position: fixed;
							left: 0;
							right: 0;
							width: 100%;
							display: flex;
							flex-wrap: nowrap;
							justify-content: center;
							align-items: stretch;
							top: inherit !important;
							/*
							.with-stats & {
								top: $full-header-height;
							}
							*/
						}

						li {
							position: relative;
							padding: 0;
							width: -webkit-fill-available;


							a {
								@include bp--lg {
									//padding: 20px 30px;
								}

								&::before {
									@include bp--lg {
										display: block;
										content: '';
										position: absolute;
										top: 0;
										left: -30px;
										right: -30px;
										bottom: 0;
										z-index: 1;
										background: $white;
										transition: background 0.3s ease;

										.cssclippathpolygon & {
											clip-path: polygon(0 0, 67% 0, 100% 100%, 33% 100%);
										}
									}
								}

								.icon-name {
									@include bp--lg {
										position: absolute;
										z-index: 2;
										border: 0;
										clip: rect(0 0 0 0);
										height: 1px;
										margin: -1px;
										overflow: hidden;
										padding: 0;
										width: 1px;
									}
								}

								.icon-image {
									display: none;
									position: relative;
									z-index: 2;
								//	max-width: 75px;
									max-height: 50px;
									width: 100%;
									height: auto;
									transition: all 0.3s ease;

									@include bp--lg {
										display: block;
									}
								}
							}

							.no-touch & a:hover,
							&.current-menu-item > a {
								&::before {
									@include bp--lg {
										background: $gray-lt;
										transition: background 0.3s ease;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	&--secondary {
		@include bp--lg {
			display: none;
		}

		ul {
			padding: 15px;

			@include bp--xs {
				padding: 25px;
			}

			li {
				display: block;
				text-transform: uppercase;
				@include font-black;
				@include rem(font-size, 17px);
				@include rem(line-height, 25px);
				
				a {
					display: inline-block;
					color: #a1a1a1;
					transition: color 0.3s ease;

					.no-touch &:hover {
						color: $red;
						transition: color 0.3s ease;
					}
				}

				ul {
					display: none;
					margin: 10px 0;
					padding: 0 15px;
					border-left: 4px #e6e6e6 solid;

					&.is-active {
						display: block;
					}
					
					li {
						ul {
							display: none;
						}
					}
				}
			}
		}
	}
}
