/* ===========================================================
  SOCIAL
=========================================================== */
.nav--social {
	display: none;
	
	&.active {
		display: block;
		max-width: 275px;
		padding: 20px 25px;

		@include bp--lg {
			margin-left: 40px;
			padding: 0;
		}
	}

	ul {
		display: flex;

		li {
			display: block;

			& + li {
				margin-left: 15px;
			}

			a {
				display: block;
				transition: color 0.3s ease;
				@include rem(font-size, 22px);
				@include rem(line-height, 22px);

				.no-touch &:hover {
					color: $black;
					transition: color 0.3s ease;
				}

				@include bp--lg {
					text-align: center;
					@include rem(font-size, 18px);
					@include rem(line-height, 18px);

					svg, i.fa-brands {
						@include center-h--unk();
					}
				}
			}

			&.ig {
				a {
					svg {
						width: auto;
						height: 19px;
						fill: $red;
						transition: fill 0.3s ease;

						@include bp--lg {
							height: 16px;
						}
					}

					.no-touch &:hover {
						svg {
							fill: $black;
							transition: fill 0.3s ease;
						}
					}
				}
			}
		}
	}

	ul,
	ul li,
	ul li a {
		@include bp--lg {
			height: 100%;
		}
	}
}

ul.social {
	&__share {
		margin: 0;
		padding: 0;
		list-style: none;

		&.margin-top {
			margin: 2em 0 0;
		}

		&.align-right {
			li {
				text-align: center;
				
				@include bp--sm {
					text-align: right;
				}
			}
		}

		li {
			display: block;
			margin-bottom: 20px;
			text-align: center;
			@include font-bold;
			@include rem(font-size, 16px);
			@include rem(line-height, 16px);

			@include bp--sm {
				text-align: left;
			}

			a {
				position: relative;
				display: inline-block;
				width: 120px;
				padding: 5px 30px 5px 10px;
				color: $black;
				text-align: left;
				cursor: pointer;

				&::before {
					display: block;
					content: '';
					position: absolute;
					top: 11px;
					left: 0;
					right: 0;
					bottom: 0;
					z-index: 1;
					max-height: 15px;
					background: $gray;
					transition: all 0.3s ease;
				}

				span {
					position: relative;
					z-index: 2;
				}

				.share-icon {
					position: absolute;
					bottom: 5px;
					right: 0;
					width: 35px;
					text-align: center;
				}

				.no-touch &:hover {
					&::before {
						top: 0;
						max-height: 999px;
						transition: all 0.3s ease;
					}
				}
			}

			.copy-text {
				display: block;
				opacity: 0;
				padding: 5px 10px;
				color: $gray-md;
				transition: opacity 0.3s ease;
				@include rem(font-size, 12px);
				@include rem(line-height, 12px);

				&.active {
					opacity: 1;
				}

				&.error {
					color: $red;
				}
			}
		}
	}
}

.twitter-widget {
	position: relative;
	padding: 30px;
	width: 285px;
	max-width: 100%;
	background-color: $black-lt;
	background-image: url('../images/bg--twitter.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: top center;

	.sidebar & {
		margin-top: 40px;
	}

	h6 {
		color: $white;

		span {
			display: inline-block;
			@include rem(font-size, 13px);
		}

		svg {
			display: inline-block;
			padding-right: 5px;
			fill: $white;
		}
	}

	p {
		margin: 0 0 1.75em;
		word-wrap: break-word;
		color: $white;
		@include font-black;
		@include rem(font-size, 22px);
		@include rem(line-height, 24px);

		a {
			color: $white;
			transition: color 0.3s ease;

			.no-touch &:hover {
				color: $red;
				transition: color 0.3s ease;
			}
		}
	}

	.border-link {
		padding: 0 0 6px;
	}

	&--small {
		p {
			@include rem(font-size, 18px);
			@include rem(line-height, 20px);
		}
	}
}
