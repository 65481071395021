/* ===========================================================
  TABLE
=========================================================== */

.table-container {
	width: 100%;
	overflow-x: auto;
	overflow-y: hidden;
	margin: 0 0 2.5em;
}

table {
	margin: 0 0 1.5em;
	min-width: 100%;
	background: $white;
	border-top: 7px $red solid;
	border-collapse: collapse;

	.stats-section & {
		margin: 0;
	}

	tr {
		position: relative;

		td,
		th {
			padding: 3px 10px;
			border: 1px #f3f3f3 solid;
			text-align: center;

			&.bold {
				@include font-bold;
			}

			&.red {
				color: $red;
			}
		}
	}

	thead {
		tr {
			td,
			th {
				@include font-bold;
			}
		}
	}

	.content--wysiwyg & {
		border-top: 0;

		tr {
			td {
				text-align: left;
			}
		}
	}

	&.small-text {
		@include rem(font-size, 12px);
		@include rem(line-height, 35px);

		@include bp--sm {
			@include rem(font-size, 14px);
		}

		tr {
			td,
			th {
				padding: 3px 7px;

				@include bp--sm {
					padding: 3px 10px;
				}
			}
		}
	}

	&.small-lh {
		@include rem(line-height, 16px);

		@include bp--sm {
			@include rem(line-height, 18px);
		}

		tr {
			td {
				padding: 15px 7px;

				@include bp--sm {
					padding: 12px 10px;
				}
			}
		}
	}
}

.table-container-wrap {
	position: relative;
	border-top: 7px $red solid;

	table {
		border-top: 0;
	}
}

.table-container.with-frozen {
	margin-left: 210px;
	width: auto;

	tr {
		position: static;
	}

	table {
		margin: 0;
	}

	@include bp--sm {
		margin-left: 250px;
	}

	&.one-frozen {
		margin-left: 160px;

		@include bp--sm {
			margin-left: 210px;
		}
	}
}

td.frozen,
th.frozen {
	position: absolute;
	top: auto;
	text-align: left;

	&:nth-of-type(1) {
		width: 40px;
		left: 0;
		border-right: 0;
		text-align: left;

		@include bp--sm {
			width: 50px;
		}
	}

	&:nth-of-type(2) {
		width: 172px;
		left: 39px;
		border-left: 0;

		@include bp--sm {
			width: 202px;
			left: 49px;
		}
	}

	.one-frozen & {
		&:nth-of-type(1) {
			width: 162px;
			left: 0;

			@include bp--sm {
				width: 100%;//212px;
			}
		}

		&:nth-of-type(2) {
			position: relative;
			width: auto;
			left: auto;
			border-left: 1px $gray-lt solid;

			@include bp--sm {
				width: auto;
				left: auto;
			}
		}
	}
}
