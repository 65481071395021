/* ===========================================================
  STATS BAR
=========================================================== */

.stats {
	/*
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 699;
	*/
	background: $gray-lt;
	padding: 0 25px;
	height: $stats-height;
	border-bottom: 1px $gray-lt solid;
	overflow: hidden;
	position: relative;

	&__arrows {
		margin: 0;
		padding: 0;
		list-style: none;

		li {
			a {
				position: absolute;
				top: 0;
				bottom: 0;
				width: 25px;

				svg {
					position: absolute;
					top: 50%;
					left: 50%;
					margin-top: -5px;
					margin-left: -5px;
					width: 10px;
					height: 10px;
					fill: $gray-dk;
					transition: fill 0.3s ease;
				}

				&.previous {
					left: 0;

					svg {
						@include rotate(180);
					}
				}

				&.next {
					right: 0;
				}

				.no-touch &:not(.slick-disabled):hover {
					svg {
						fill: $red;
						transition: fill 0.3s ease;
					}
				}

				&.slick-disabled {
					opacity: 0.2;
					pointer-events: none;
				}
			}
		}
	}

	&__wrap {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		background: $white;
		overflow: hidden;
	}

	.slick-slide {
		border-left: 2px $gray-lt solid;
	}

	&__game {
		position: relative;
		display: block;
		width: 14.2857%;

		h4 {
			padding: 3px 0;
			background: $gray-lt;
			color: $gray-dk !important;
			text-transform: uppercase;
			text-align: center;
			transition: color 0.3s ease;
			@include font-black;
			@include rem(font-size, 10px);
			@include rem(line-height, 12px);

			a {
				color: $gray-dk;
				transition: color 0.3s ease;

				.no-touch &:hover {
					color: $red;
					transition: color 0.3s ease;
				}
			}

			.final {
				color: $black;
				transition: color 0.3s ease;
			}

			.in-progress {
				color: #2dc776;
				transition: color 0.3s ease;
			}
		}

		&--score {
			padding: 2px;
		}

		&--team {
			position: relative;
			display: flex;
			padding: 2px 0;
			align-items: center;

			.team-logo {
				width: 15%;

				img {
					display: block;
					margin: 0 auto;
					max-width: 25px;
					max-height: 18px;
					width: auto;
					height: auto;
				}
			}

			.team-name {
				width: 70%;
				padding: 0 10px;
				text-transform: uppercase;
				color: $black;
				@include font-black;
				@include rem(font-size, 10px);
				@include rem(line-height, 12px);

				span {
					display: inline-block;
					padding-right: 5px;
					@include rem(font-size, 11px);
				}

				a {
					color: $black;
					transition: color 0.3s ease;

					.no-touch &:hover {
						color: $red;
						transition: color 0.3s ease;
					}
				}
			}

			.points {
				width: 15%;
				color: $black;
				@include font-black;
				@include rem(font-size, 12px);
				@include rem(line-height, 12px);
			}

			&.lost {
				.team-name,
				.team-name a,
				.points {
					color: $gray-dk;
				}
			}

			&.won {
				.win-indicator {
					position: absolute;
					top: 50%;
					right: -2px;
					display: block;
					margin-top: -3px;
					width: 6px;
					height: 6px;
					background-image: url('../images/indicator.png');
					background-repeat: no-repeat;
					background-size: cover;
					background-position: top left;
				}
			}
		}

		&-link {
			display: block;
			background: transparent;
			transition: background 0.3s ease;

			&:focus {
				outline: none;
			}

			.no-touch &:hover {
				background: $gray-lt;
				transition: background 0.3s ease;
				
				h4 {
					color: $red;
					transition: color 0.3s ease;

					.final {
						color: $red;
						transition: color 0.3s ease;
					}

					.in-progress {
						color: $red;
						transition: color 0.3s ease;
					}
				}
			}
		}
	}
}

/* ===========================================================
  STATS TABS
=========================================================== */
.print-header-block{
	display: none;

}

.stats-tabs {
	margin-top: -30px;
}

.stats-tab {
	display: none;

	&.is-active {
		display: block;
	}

	&.margin-top {
		margin-top: 40px;
	}

	h3 {
		margin-bottom: 40px;
		padding-bottom: 5px;
		border-bottom: 7px $red solid;
		text-transform: none;
		@include font-bold;
		@include rem(font-size, 20px);
		@include rem(line-height, 25px);

		&.no-border {
			margin-bottom: 25px;
			padding-bottom: 0;
			border-bottom: 0;
		}

		#leaders & {
			display: none;
			max-width: 260px;
			margin-left: auto;
			margin-right: auto;

			@include bp--sm {
				display: block;
				max-width: 650px;
			}

			@include bp--lg {
				max-width: none;
			}
		}
	}

	a {
		color: #000;
		transition: all 0.2s ease;

		&:hover {
			color: $red;
		}
	}
}

ul.stats-nav {
	margin: 0;
	padding: 0;
	list-style: none;
	text-align: center;

	li {
		padding: 0;
		text-transform: uppercase;

		a {
			display: block;
			padding: 15px 10px;

			@include bp--sm {
				padding: 15px;
			}
		}
	}

	&--main {
		li {
			@include font-black;
			@include rem(font-size, 15px);
			@include rem(line-height, 15px);

			@include bp--sm {
				@include rem(font-size, 20px);
				@include rem(line-height, 20px);
			}

			a {
				background: $gray-lt;
				color: $black;
				border-bottom: 12px $black solid;
				transition: all 0.3s ease;
			}

			&.is-active a,
			.no-touch & a:hover {
				background: $white;
				border-color: $red;
				transition: all 0.3s ease;
			}
		}
	}

	&--sub {
		margin-bottom: 40px;
		border: 1px $gray solid;
		border-top: 0;

		li {
			@include rem(font-size, 13px);
			@include rem(line-height, 13px);
			@include font-bold;

			@include bp--sm {
				@include rem(font-size, 16px);
				@include rem(line-height, 16px);
			}

			a {
				padding: 20px 15px;
				background: $white;
				color: $gray-dk;
				transition: all 0.3s ease;
			}

			&.is-active a,
			.no-touch & a:hover {
				background: $gray-lt;
				transition: all 0.3s ease;
			}

			& + li {
				border-left: 1px $gray solid;
			}
		}
	}

	&--teams {
		display: flex;
		flex-wrap: wrap;
		overflow-x: auto;
		border-bottom: 0;

		@include bp--sm {
			flex-wrap: nowrap;
			border-bottom: 1px $gray solid;
		}

		li {
			display: block;
			width: 25%;
			border-bottom: 1px $gray solid;

			@include bp--sm {
				width: 12.5%;
				border-bottom: 0;
			}

			a {
				display: block;
				width: 100%;
				height: 100%;
				padding: 10px;

				img {
					max-width: 50px;
					max-height: 40px;
					width: 100%;
					height: auto;
					@include center-h--unk();
				}
			}
		}
	}

	&--weeks {
		display: flex;
		flex-wrap: wrap;
		
		//overflow-x: auto;
		border:0;
		
		//border-left: 1px $gray solid;
		//border-bottom: 1px solid #ebebeb;

		&.center-align{
			justify-content: center;
		}

		@include bp--md {
			flex-wrap: nowrap;
			flex-grow: 1;
		
			//border-bottom: 1px $gray solid;
		}

		li {
			display: block;
			width: 20%;
			//border: 1px $gray solid;
			border: 0;
			box-sizing: border-box;
			outline: 1px $gray solid;
			outline-offset: -1px;

			&:last-child{
				//border-right: 1px $gray solid;
		
			}

			@include bp--md {
				width: 10%;
			//	border-bottom: 0;
			}

			a {
				display: block;
				width: 100%;
				height: 100%;

				@include bp--xs(max) {
					padding: 20px 5px;
				}
			}
		}
	}
}

/* ===========================================================
  STATS: LEADERBOARD
=========================================================== */
.stats-data--leaderboard {
	max-width: 260px;
	margin: 0 auto;
	
	@include bp--sm {
		max-width: 650px;
	}

	@include bp--lg {
		max-width: none;
	}
}

.leaders {
	margin: 0 auto;

	> h4 {
		max-width: 260px;
		margin: 0 auto;
		text-transform: none;
		@include font-bold;
		@include rem(font-size, 20px);
		@include rem(line-height, 25px);
	}

	.leader {
		.person-photo {
			position: relative;
			width: 240px;
			margin-top: -50px;

			.photo-wrap {
				width: 240px;
				height: 188px;

				.polygon {
					background: $gray-lt;
				}
			}

			.team-logo {
				position: absolute;
				right: -20px;
				bottom: -20px;

				img {
					max-width: 75px;
					max-height: 55px;
					width: 100%;
					height: auto;
				}
			}

			.leader-logo {
				height: 55px;
				width: 75px;
				background-repeat: no-repeat;
			}
		}
	}

	&__wrap {
		max-width: 260px;
		margin-top: 50px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 50px;
		border: 1px $gray-lt solid;
	}
}

.leaderboard {
	@include bp--sm {
		height: 140px;
		overflow-y: scroll;
	}

	.leaderboard-name {
		height: 35px;
		border-top: 1px $gray-lt solid;
	}
}

.leaderboard-name {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;

	.leader & {
		//padding: 10px 0;
		padding: 20px 0 10px;
	}

	span {
		display: block;
		text-align: center;
		@include font-bold;
		@include rem(font-size, 11px);
		@include rem(line-height, 13px);

		.leader & {
			@include rem(font-size, 16px);
			@include rem(line-height, 20px);
		}

		&.rank {
			width: 11.5%;
		}

		&.name {
			width: 53.8%;
			text-align: left;

			.leader & {
				width: auto;
				padding-right: 10px;
			}
		}

		&.number {
			width: 13.5%;
			color: $red;
		}

		&.team {
			width: 21.2%;
			padding: 20px 0;

			img {
				width: 30px;
				height: auto;
			}
		}
	}
}

/* ===========================================================
  STATS: TABLE STYLES
=========================================================== */

td {
	&.team-cell {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}

	.team {
		display: block;

		img {
			display: block;
			margin: 0 auto;
			vertical-align: middle;
			max-width: 50px;
			max-height: 25px;
			width: 100%;
			height: auto;
		}
	}

	&.with-name {
		padding-top: 4px !important;
		padding-bottom: 3px !important;
		height: 43px;

		.team {
			display: inline-block;
			vertical-align: middle;
			margin-right: 5px;

			img {
				display: inline;
				margin: 0;
				max-width: 40px;
				max-height: 20px;
			}
		}

		.team-name {
			display: inline-block;
			vertical-align: middle;
		}

		.team-record {
			display: inline-block;
			vertical-align: middle;
			margin-left: 8px;
			color: $gray-dk;
			@include font-regular;
			@include rem(font-size, 12px);
		}
	}
}

.stats-scores__game {
	margin-bottom: 30px;
	padding: 0;

	@include bp--sm {
		//margin-bottom: 0px;

		&:nth-of-type(odd) {
			padding-right: 15px;
		}

		&:nth-of-type(even) {
			padding-left: 15px;
		}
	}

	&.is-expanded {
		width: 100% !important;
		flex-basis: 100%;
		max-width: 100%;
		padding: 0;

		> .table-container-wrap {
			max-width: 625px;
			margin: 0 auto 40px;
		}
	}

	> .table-container-wrap .table-container,
	table.summary-box-score {
		margin: 0;
	}

	table.summary-box-score {
		tr {
			&.lost {
				opacity: 0.5;
			}

			td {
				padding: 10px;
				border: 0;
				vertical-align: middle;
				height: auto;

				&:nth-of-type(1) {
					width: 76%;
					text-align: left;
				}

				&:nth-of-type(2),
				&:nth-of-type(3),
				&:nth-of-type(4) {
					width: 8%;
				}

				&.box-score-link {
					text-align: right;
				}
			}
		}

		thead {
			tr {
				td {
					color: $gray-dk;
					text-transform: uppercase;
					@include font-bold;
					@include rem(font-size, 12px);

					.final {
						color: $black;
					}

					.in-progress {
						color: #2dc776;
					}
				}
			}
		}

		tbody {
			tr {
				td:not(.with-name) {
					color: $gray-dk;
					@include font-bold;
				}

				&:last-child {
					background: $gray-lt;

					td {
						padding: 7px;
						text-transform: uppercase;
						@include rem(font-size, 12px);

						a {
							color: $black;
							transition: color 0.3s ease;

							.no-touch &:hover {
								color: $red;
								transition: color 0.3s ease;
							}
						}
					}
				}
			}
		}
	}
}

.back-link {
	margin: 20px auto 30px;
	max-width: 620px;
	text-transform: uppercase;
	text-align: left;
	@include font-bold;
	@include rem(font-size, 12px);
	@include rem(line-height, 12px);

	a {
		display: inline-block;
		color: $black;
		transition: color 0.3s ease;

		.no-touch &:hover {
			color: $red;
			transition: color 0.3s ease;
		}
	}
}


.stats-scores {
	.expanded-view & {
		.stats-scores__game:not(.is-expanded) {
			display: none;
		}
	}

	
	.full-box-score {
		display: none;

		.expanded-view & {
			display: block;
		}

		

		h3 {
			margin-bottom: 10px;
		}
	}
}

.stats-tab--scores {
	

	.stats-scores__game {
		h3 {
			display: none;
		}
	}

	&.expanded-view {
		> h3 {
			display: none;
		}

		.stats-scores__game {
			h3 {
				display: block;
			}
		}
	}
}

#results {
	.stats-tab {
		table {
			tr {
				td {
					&:nth-of-type(3) {
						width: 12.5%;
					}

					&:nth-of-type(4),
					&:nth-of-type(6) {
						width: 27%;
					}
				}
			}
		}
	}
}

th[data-sort='int'],
th[data-sort='float'],
th[data-sort='lastname'] {
	position: relative;
	padding-left: 25px !important;
	cursor: pointer;

	&.frozen {
		position: absolute;
		padding-left: 30px !important;
	}

	&::before,
	&::after {
		position: absolute;
		left: 9px;
		display: block;
		content: '';
		width: 0;
		height: 0;
		border-style: solid;

		@include bp--sm {
			left: 12px;
		}
	}

	&::before {
		top: 14px;
		border-width: 0 4px 4px 4px;
		border-color: transparent transparent $gray-md transparent;
		transition: border-color 0.3s ease;
	}

	&::after {
		bottom: 14px;
		border-width: 4px 4px 0 4px;
		border-color: $gray-md transparent transparent transparent;
		transition: border-color 0.3s ease;
	}

	&.sorting-asc {
		&::before {
			border-color: transparent transparent $red transparent;
			transition: border-color 0.3s ease;
		}
	}

	&.sorting-desc {
		&::after {
			border-color: $red transparent transparent transparent;
			transition: border-color 0.3s ease;
		}
	}
}

/* ===========================================================
	TEAM IMAGES 

35417 = 3hmonsters-1.svg
35416 = 3scompany-1.svg
35418 = ballhogs-1.svg
35419 = ghostballers-1.svg
35427 = killer3s-1.svg
35435 = power-1.svg
35436 = trilogy-1.svg
35434 = tristate-1.svg


http://big3wp.test/wp-content/themes/big3/assets/dist/images/svg/teams/trilogy-1.svg
=========================================================== */

.apiload {
	background-color: #f6f6f6;
	width: 100%;
	height: 100%;
	position: relative;
	min-height: 300px;
	//z-index:1;
	//z-index: 800;
}

.no-data{
	background-color: #f6f6f6;
	width: 100%;
	height: 100%;
	position: relative;
	min-height: 300px;
	//z-index:1;
//z-index: 800;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;

}

#statLoaderUi {
	margin-bottom: 30px;
	perspective: 600px;
	

	.rotating-parallelogram {
		z-index: 1;
	}

	.apiload {
		min-height: 80px;
	}
	
	.no-data{
		min-height: 80px;
	}

}

.table-logo {
	background-size: 30px;
	background-repeat: no-repeat;
	background-position: 50% 50%;


	& .hide-text {
		border: 0;
		clip: rect(0 0 0 0);
		height: 100%;
		overflow: hidden;
		width: 100%;
		text-indent: -9999px;
	}
}

.team-logo-left {
	background-size: 30px;
	background-repeat: no-repeat;
	background-position: 5% 50%;
}

.team-logo-left .team,
.team-logo-left .team-name {
	padding-left: 50px;
}

.scoreboard-logo {
	height: 18px;
	background-repeat: no-repeat;
	background-position: 50% 50%;
}

.team-35417 {
	background-image: url('../images/svg/teams/3hmonsters-1.svg');
}

.team-35416 {
	background-image: url('../images/svg/teams/3scompany-1.svg');
}

.team-35418 {
	background-image: url('../images/svg/teams/ballhogs-1.svg');
}

.team-35419 {
	background-image: url('../images/svg/teams/ghostballers-1.svg');
}

.team-35427 {
	background-image: url('../images/svg/teams/killer3s-1.svg');
}

.team-35435 {
	background-image: url('../images/svg/teams/power-1.svg');
}

.team-35436 {
	background-image: url('../images/svg/teams/tristate-1.svg');
}

.team-35434 {
	background-image: url('../images/svg/teams/trilogy-1.svg');
}

.team-40994 {
	background-image: url('https://big3.com/wp-content/uploads/2019/01/Triplets-2.svg');
}

.team-40993 {
	background-image: url('https://big3.com/wp-content/uploads/2019/02/Enemies2.svg');
}

.team-40991 {
	background-image: url('https://big3.com/wp-content/uploads/2019/01/Aliens.svg');
}

.team-40992 {
	background-image: url('https://big3.com/wp-content/uploads/2019/02/Bivouac300x300.svg');
}

.team-big3{
	background-image: url('https://big3.com/wp-content/themes/big3/assets/dist/images/logo@1x.png');
}

.noExpansion{
	.expansion{
		display: none;
	}
}


.page-template-tpl-stats #season-select{
	//display: none;
}
