/* ===========================================================
   CLEARFIX
=========================================================== */
%clearfix {
	*zoom: 1;

	&::before,
	&::after {
		content: '';
		display: table;
	}

	&::after {
		clear: both;
	}
}

/* ===========================================================
   RESPONSIVE
=========================================================== */

@mixin bp--xs($type: min) {
	@if $type == max {
		@media only screen and (#{$type}-width: $bp-xs-max) { @content; }
	}
	@else {
		@media only screen and (#{$type}-width: $bp-xs) { @content; }
	}
}

@mixin bp--sm($type: min) {
	@if $type == max {
		@media only screen and (#{$type}-width: $bp-sm-max) { @content; }
	}
	@else {
		@media only screen and (#{$type}-width: $bp-sm) { @content; }
	}
}

@mixin bp--md($type: min) {
	@if $type == max {
		@media only screen and (#{$type}-width: $bp-md-max) { @content; }
	}
	@else {
		@media only screen and (#{$type}-width: $bp-md) { @content; }
	}
}

@mixin bp--lg($type: min) {
	@if $type == max {
		@media only screen and (#{$type}-width: $bp-lg-max) { @content; }
	}
	@else {
		@media only screen and (#{$type}-width: $bp-lg) { @content; }
	}
}

@mixin bp--xl($type: min) {
	@if $type == max {
		@media only screen and (#{$type}-width: $bp-xl-max) { @content; }
	}
	@else {
		@media only screen and (#{$type}-width: $bp-xl) { @content; }
	}
}

@mixin bp-custom($width, $type: min) {
	@media only screen and (#{$type}-width: $width) {
		@content;
	}
}

@mixin responsive-ratio($x, $y, $pseudo: false) {
	$padding: unquote(($y / $x) * 100 + '%');
	@if $pseudo {
		&::before {
			@include pseudo($pos: relative);
			width: 100%;
			padding-top: $padding;
		}
	}
	@else {
		padding-top: $padding;
	}
}

@mixin ratio-box($ratio: 1 / 1) {
	position: relative;
	overflow: hidden;

	> * {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&::before {
		display: block;
		padding-top: (1 / $ratio) * 100%;
		height: 0;
		content: '';
	}
}

/* ===========================================================
   ELEMENT POSITIONING
=========================================================== */

@mixin push-auto {
	margin-left: auto;
	margin-right: auto;
}

@mixin rotate($deg) {
	transform: rotate(#{$deg}deg);
}

// Center horizontally & vertically
@mixin center-both {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

// Vertically centers block elements with known height.
@mixin center-h($height) {
	position: absolute;
	top: 50%;
	height: $height;
	margin-top: -($height / 2);
}

// Vertically centers block elements with unknown height.
@mixin center-h--unk {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

// Cover the entire element
@mixin full-cover {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
}

/* ===========================================================
   ELEMENT STATES / ANIMATIONS
=========================================================== */

@mixin hardware($backface: true, $perspective: 1000) {
	@if $backface {
		backface-visibility: hidden;
	}
	perspective: $perspective;
}

@mixin on-event($self: false) {
	@if $self {
		&,
		&:hover,
		&:active,
		&:focus {
			@content;
		}
	}
	@else {
		&:hover,
		&:active,
		&:focus {
			@content;
		}
	}
}

@mixin hoverer($property, $value1, $value2) {
	#{$property}: $value1;

	&:hover {
		#{$property}: $value2;
	}
}

@mixin placeholder {
	&.placeholder { @content; }
	&::-webkit-input-placeholder { @content; }
	&::-moz-placeholder { @content; }
	&:-moz-placeholder { @content; }
	&:-ms-input-placeholder { @content; }
}

/* ===========================================================
   ELEMENT CREATION
=========================================================== */

@mixin pseudo($display: block, $pos: relative, $content: '') {
	content: $content;
	display: $display;
	position: $pos;
}

@mixin css-triangle($color, $direction, $size: 6px, $position: absolute, $round: false) {
	@include pseudo($pos: $position);
	width: 0;
	height: 0;
	@if $round {
		border-radius: 3px;
	}
	@if $direction == down {
		border-left: $size solid transparent;
		border-right: $size solid transparent;
		border-top: $size solid $color;
		margin-top: 0 - round($size / 2.5);
	}
	@else if $direction == up {
		border-left: $size solid transparent;
		border-right: $size solid transparent;
		border-bottom: $size solid $color;
		margin-bottom: 0 - round($size / 2.5);
	}
	@else if $direction == right {
		border-top: $size solid transparent;
		border-bottom: $size solid transparent;
		border-left: $size solid $color;
		margin-right: -$size;
	}
	@else if  $direction == left {
		border-top: $size solid transparent;
		border-bottom: $size solid transparent;
		border-right: $size solid $color;
		margin-left: -$size;
	}
}

/* ===========================================================
    GRADIENTS
=========================================================== */

@mixin background-gradient($start-color, $end-color, $orientation) {
	background: $start-color;
	@if $orientation == 'vertical' {
		background: -webkit-linear-gradient(top, $start-color, $end-color);
		background: linear-gradient(to bottom, $start-color, $end-color);
	}
	@else if $orientation == 'horizontal' {
		background: -webkit-linear-gradient(left, $start-color, $end-color);
		background: linear-gradient(to right, $start-color, $end-color);
	}
	@else {
		background: -webkit-radial-gradient(center, ellipse cover, $start-color, $end-color);
		background: radial-gradient(ellipse at center, $start-color, $end-color);
	}
}

/* ===========================================================
    FONTS
=========================================================== */

@mixin font-light {
	font-family: $font-def;
	font-weight: 300;
}

@mixin font-regular {
	font-family: $font-def;
	font-weight: 400;
}

@mixin font-bold {
	font-family: $font-def;
	font-weight: 700;
}

@mixin font-black {
	font-family: $font-def;
	font-weight: 900;
}

/* ===========================================================
    TEXT
=========================================================== */

@function parseint($n) {
	@return $n / ($n * 0 + 1);
}

@mixin rem($property, $values) {
	$px : ();
	$rem: ();
	@each $value in $values {
		@if $value == 0 or $value == auto {
			$px : append($px, $value);
			$rem: append($rem, $value);
		}
		@else {
			$unit: unit($value);
			$val: parseint($value);
			@if $unit == 'px' {
				$px: append($px, $value);
				$rem: append($rem, ($val / 10 + rem));
			}
			@if $unit == 'rem' {
				$px: append($px, ($val * 10 + px));
				$rem: append($rem, $value);
			}
		}
	}
	@if $px == $rem {
		#{$property}: $px;
	}
	@else {
		#{$property}: $px;
		#{$property}: $rem;
	}
}

@mixin hide-text {
	overflow: hidden;
	text-indent: -9999px;
}

@mixin show-text {
	overflow: visible;
	text-indent: 0;
}

@mixin truncate-text {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

