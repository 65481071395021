/* ===========================================================
  DRAFTPOOL
=========================================================== */

.draft-grid{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    border-top: 10px solid #e51937;


    .draft-entry{
        width: 100%;
        padding: 15px;
		border: 1px solid #ebebeb;
       
       
		@include bp--sm {
			width: calc(100% / 3);
		}
     

        .bolded{
            font-weight: bold;
        }

        a{
            display: block;
            transition: color .2s ease-out;

            &:hover{
                color: #959595;
            }
        }

        .person-photo{


    position: relative;
    width: 100%;
    height: 150px;
    margin: 0 auto 20px;

        }

        .photo-wrap{
            /*
            position: absolute;
            right: 0;
            bottom: 0;
            z-index: 2;
            display: block;
            max-width: 100%;
            max-height: 100%;
            width: 100%;
            height: 150px;
            margin: 0 auto;
            */

            position: relative;
            /* right: 0; */
            /* bottom: 0; */
            z-index: 2;
            display: block;
            max-width: 100%;
            max-height: 100%;
            width: 100%;
            height: 100%;
            margin: 0 auto;
            padding: 0;
            clip-path: none;
    
            img{
                /*
                mix-blend-mode: multiply;
                width: 150px;
                height: 150px;
                    transform: translateX(-25%);

                       */
                width: auto;
                height: 100%;

                margin: 0 auto;
                position: relative;

            }
        }
    }

    
}
