/* ===========================================================
  SIDEBAR
=========================================================== */

.sidebar-wrap {
	position: relative;

	.sidebar {
		padding: 30px 20px;

		.no-padding & {
			padding-top: 0 !important;
		}

		@include bp--md {
			padding: 30px;

			&.col-lg-4 {
				padding: 20px;
			}

			.no-padding & {
				padding-left: 0 !important;
			}
		}

		@include bp--lg {
			padding: 50px;

			&.col-lg-4 {
				padding: 20px;
			}
		}

		&__intro {
			margin: 0 0 50px;

			.blog &,
			.category & {
				margin: 0 0 30px;
			}
		}

		.schedule-widget,
		.ad__unit--side {
			margin-left: auto;
			margin-right: auto;

			@include bp--sm {
				margin-left: 0;
				margin-right: 0;
			}
		}

		&.col-lg-4 {
			.schedule-widget,
			.ad__unit--side {
				@include bp--sm {
					margin-left: auto;
					margin-right: auto;
				}

				@include bp--lg {
					margin-left: 0;
					margin-right: 0;
				}
			}
		}

		&.first-md {
			.schedule-widget,
			.ad__unit--side {
				@include bp--sm {
					margin-left: auto;
					margin-right: auto;
				}

				@include bp--md {
					margin-left: 0;
					margin-right: 0;
				}
			}
		}
	}

	.main {
		padding: 20px;
		background: $gray-lt;

		.no-padding & {
			padding-top: 0 !important;
		}

		.no-bg & {
			background: none;
		}

		@include bp--md {
			padding: 30px;

			&.col-lg-8 {
				padding: 20px;
			}

			.no-padding & {
				padding-right: 0 !important;
			}
		}

		@include bp--lg {
			padding: 50px;

			&.col-lg-8 {
				padding: 20px;
			}
		}
	}
}

.sidebar__intro {
	h1 {
		text-transform: none;
		@include rem(font-size, 30px);
		@include rem(line-height, 36px);
	}

	p {
		@include rem(font-size, 14px);
		@include rem(line-height, 20px);
	}

	&--desktop {
		display: none;

		@include bp--sm {
			display: block;
		}

		&.md {
			@include bp--sm {
				display: none;
			}

			@include bp--md {
				display: block;
			}
		}

		&.lg {
			@include bp--sm {
				display: none;
			}

			@include bp--lg {
				display: block;
			}
		}
	}

	&--mobile {
		padding: 20px;

		@include bp--sm {
			display: none;
		}

		&.md {
			@include bp--sm {
				display: block;
			}

			@include bp--md {
				display: none;
			}
		}

		&.lg {
			@include bp--sm {
				display: block;
			}

			@include bp--lg {
				display: none;
			}
		}
	}
}

ul.sidebar__menu {
	margin: 0 0 1.5em;
	padding: 0;
	list-style: none;

	&#content-swap-menu {
		display: none;
	}

	.sidebar &--categories {
		display: none;

		@include bp--lg {
			display: block;
		}
	}

	@include bp--sm {
		margin: 0 0 3em;

		&#content-swap-menu {
			display: block;
		}
	}

	@include bp--lg {
		margin: 0 0 6em;
	}

	li {
		position: relative;
		margin: 0;
		@include font-bold;
		@include rem(font-size, 20px);
		@include rem(line-height, 25px);

		a {
			position: relative;
			display: inline-block;
			padding-right: 75px;
			color: $black;
			transition: color 0.3s ease;
		}

		&.current-menu-item {
			a {
				color: $red;

				&::after {
					display: block;
					content: '';
					position: absolute;
					top: 50%;
					right: 0;
					margin-top: -1.5px;
					width: 60px;
					height: 3px;
					background: $red;
				}
			}
		}

		.no-touch & a:hover {
			color: $red;
			transition: color 0.3s ease;
		}
	}
}

.sidebar--sticky {
	@include bp--lg {
		position: sticky;
		top: $header-height + 30px;

		.with-stats &,
		.with-video & {
			top: $full-header-height + 30px;
		}
	}
}
