/* ===========================================================
   TYPOGRAPHY
=========================================================== */

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	padding: 0;
}

h1:not(.header__logo),
.h1-like {
	color: $font-color;
	text-transform: uppercase;
	letter-spacing: 0.025em;
	@include font-bold;
	@include rem(font-size, 60px);
	@include rem(line-height, 70px);

	&.page-title {
		text-transform: none;
		letter-spacing: none;
		@include rem(font-size, 30px);
		@include rem(line-height, 36px);

		@include bp--sm {
			@include rem(font-size, 40px);
			@include rem(line-height, 48px);
		}
	}
}

h2 {
	color: $font-color;
	@include font-black;
	@include rem(font-size, 36px);
	@include rem(line-height, 44px);
}

h3 {
	color: $font-color;
	text-transform: uppercase;
	@include font-black;
	@include rem(font-size, 30px);
	@include rem(line-height, 38px);
}

h4 {
	color: $font-color;
	text-transform: uppercase;
	@include font-black;
	@include rem(font-size, 24px);
	@include rem(line-height, 32px);
}

h5 {
	color: $font-color;
	letter-spacing: 0.025em;
	text-transform: uppercase;
	@include font-black;
	@include rem(font-size, 20px);
	@include rem(line-height, 28px);

	&.page-subtitle {
		margin: 0;
		color: $red;
	}
}

h6 {
	color: $font-color;
	text-transform: uppercase;
	@include font-black;
	@include rem(font-size, 16px);
	@include rem(line-height, 24px);
}

a {
	color: $link-color;
	text-decoration: none;

	&.border-link {
		display: block;
		padding: 0 0 10px;
		text-transform: uppercase;
		color: $black;
		border-bottom: 6px $black solid;
		transition: all 0.3s ease;
		@include font-black;
		@include rem(font-size, 18px);
		@include rem(line-height, 18px);

		svg {
			fill: $black;
			transition: fill 0.3s ease;
		}

		.no-touch &:hover {
			color: $red;
			border-color: $red;
			transition: all 0.3s ease;

			svg {
				fill: $red;
				transition: fill 0.3s ease;
			}
		}

		&--red {
			color: $red;
			border-color: $red;

			.no-touch &:hover {
				color: $white;
				border-color: $white;
			}
		}

		@include bp--sm {
			padding: 0 0 15px;
			border-width: 12px;
			@include rem(font-size, 20px);
			@include rem(line-height, 20px);
		}
	}
}

.content {
	h1,
	h2,
	h3,
	h4,
	h5 {
		margin: 0 0 0.5em;
	}

	p {
		margin: 0 0 1.5em;
	}

	ul {
		margin: 0 0 1.5em 1.25em;
	}

	ol {
		margin: 0 0 1.5em 1em;
	}

	blockquote {
		margin: 0 0 1.5em;
		padding: 1.5em 2em;
		background: $gray;
	}

	&--wysiwyg {
		a {
			text-decoration: underline;
			transition: color 0.3s ease;

			.no-touch &:hover {
				color: $black;
				transition: color 0.3s ease;
			}
		}
	}

	.twitter-tweet {
		margin: 0 0 1.5em;
	}
}

hr {
	margin: 1.5em 0;
	background: $gray;
	width: 100%;
	height: 1px;
	border: 0;
	box-sizing: content-box;
}

.columns {
	margin: 0 0 1.5em;

	&-2 {
		@include bp--xs {
			column-count: 2;
			column-gap: 20px;
		}
	}

	&-3 {
		@include bp--xs {
			column-count: 2;
			column-gap: 20px;
		}

		@include bp--sm {
			column-count: 3;
		}
	}

	&-4 {
		@include bp--xs {
			column-count: 2;
			column-gap: 20px;
		}

		@include bp--sm {
			column-count: 3;
		}

		@include bp--lg {
			column-count: 4;
		}
	}

	p {
		display: none;
	}
}
